import React, { Component } from 'react';
import { Grid, Segment, List, Form, Button, Header, Loader, Divider } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import ErrorModal from '../utils/ErrorModal';
import OkModal from '../utils/OkModal';

const roles = {
    secretary: strings.role_secretary,
    admin: strings.role_admin,
}

class User extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            user: null,
            loading: false,
            old_password: '',
            new_password: '',
            confirm_new_password: '',
            error: false,
            error_generic: false,
            error2: false,
            ok: false
        };
    }

    componentDidMount() {
        this.getUser()
    }

    saveChanges = async () => {
        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_user_password(this.state.accessToken, this.state.user.id_user,
                this.state.old_password,
                this.state.new_password);

            if (response.data.data != null) {
                this.setState({
                    loading: false,
                    ok: true
                });
            } else {
                this.setState({
                    loading: false,
                    error2: true
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
                error_generic: true
            });
        }
    }


    getUser = async () => {
        this.setState({
            loading: true,
        })

        try {
            let response = await API.loginToken(this.state.accessToken);
            if (response.data.data != null) {
                const user = response.data.data

                this.setState({
                    user: user,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    handleChangeNewPassword = (e, { value }) => {
        this.setState({ new_password: value });
    }

    handleChangeOldPassword = (e, { value }) => {
        this.setState({ old_password: value });
    }


    handleChangeConfirmNewPassword = (e, { value }) => {
        this.setState({ confirm_new_password: value });
    }

    handleSaveClick = () => {
        if (this.state.confirm_new_password === this.state.new_password && this.state.new_password.length >= 8) {
            this.saveChanges();
        } else {
            alert("La password nuova non coincide oppure non è valida")
        }
    }

    render() {

        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_USER} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>
                            <NavigationHeader title={strings.title_admin}></NavigationHeader>
                            <ErrorModal error_message={strings.message_password_not_equal} open={this.state.error} close={() => this.setState({ error: false })} />
                            <ErrorModal open={this.state.error_generic} close={() => this.setState({ error_generic: false })} />
                            <ErrorModal error_message={strings.message_error_wrong_credentials} open={this.state.error2} close={() => this.setState({ error2: false })} />

                            <OkModal open={this.state.ok} close={() => this.setState({ ok: false })} />
                            <Loader className='custom-loader' active={this.state.loading} disabled={!this.state.loading} />

                        <List>
                            <List.Item>
                                <List.Header as='h3'>{this.state.user !== null ? this.state.user.email : ''}</List.Header>
                                {strings.email}
                            </List.Item>
                            <List.Item>
                                <List.Header as='h3'>{this.state.user !== null ? roles[this.state.user.role] != undefined ? roles[this.state.user.role] : '' : ''}</List.Header>
                                {strings.role}
                            </List.Item>
                        </List>

                        <Divider />
                        <Form>
                            <Header as='h3'>{strings.edit_password_label}</Header>
                            <Form.Input width={6} type='password'
                                label={strings.old_password} placeholder={strings.old_password}
                                onChange={this.handleChangeOldPassword} />
                            <Form.Input width={6} type='password'
                                label={strings.new_password} placeholder={strings.new_password}
                                onChange={this.handleChangeNewPassword} />
                            <Form.Input width={6} type='password'
                                label={strings.confirm_new_password} placeholder={strings.confirm_new_password}
                                onChange={this.handleChangeConfirmNewPassword} />
                            <Button className='third-color-background white-color' onClick={() => this.handleSaveClick()}>{strings.save_new_password}</Button>
                        </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withCookies
)(User)