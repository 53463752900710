import React from 'react'
import { Table, Icon, Button, Loader, Menu } from 'semantic-ui-react'
import { strings } from '../core/strings';


const WorkslistTable = (props) => {

    let rows = props.works.map(p => {
        return (
            <Table.Row textAlign='center' key={p.id_work}>
                <Table.Cell width='3'>{p.name_fr}</Table.Cell>
                <Table.Cell width='3'>{p.name_it} </Table.Cell>
                <Table.Cell width='3'>{p.name_en}</Table.Cell>
                <Table.Cell width='1'>{p.code}</Table.Cell>
                <Table.Cell width='1'>{p.price}</Table.Cell>
                <Table.Cell width='1'>
                    <Button className='third-color-background white-color'
                        onClick={() => props.onEditWork(p.id_work)} >
                        <Icon name='edit outline' />
                        {strings.edit_text_button}
                    </Button>
                </Table.Cell>
            </Table.Row>
        )
    });

    /*let prev, next, page;

    if (props.pagination) {

        if (props.pagination.prev) {
            prev =
                <Menu.Item onClick={() => props.changePage(props.pagination.prev)} as='a' icon>
                    <Icon name='chevron left' />
                </Menu.Item>
        }
        if (props.pagination.next) {
            next =
                <Menu.Item onClick={() => props.changePage(props.pagination.next)} as='a' icon>
                    <Icon name='chevron right' />
                </Menu.Item>
        }
    }*/

    return (
        <div>
            <Loader active={props.loading} />
            <Table sortable celled structured>
                <Table.Header>
                    <Table.Row textAlign='center'>
                        <Table.HeaderCell rowSpan='1'>{strings.table_material_name_fr}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_material_name_it}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_material_name_en}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.code}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.price}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'></Table.HeaderCell>
                    </Table.Row>

                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
                <Table.Footer>
                </Table.Footer>
            </Table>
        </div>

    );
}


export default (WorkslistTable);

