import React, { Component } from 'react';
import { Grid, Segment, Button, Loader, Form } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import DentistsTable from './DentistsTable';

class DentistsRegistry extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            dentists: [],
            add: false,
            dentist: {}
        };
    }


    componentDidMount() {
        this.getDentists();
    }

    addDentist = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_dentist(this.state.accessToken, this.state.dentist);
            if (response.data.data != null) {

                this.setState({
                    loading: false,
                    add: false
                });

                this.getDentists();

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    getDentists = async () => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_dentists(this.state.accessToken);
            if (response.data.data != null) {
                const dentists = [...response.data.data]
                //const pagination = Object.assign({}, response.data.pagination)
                this.setState({
                    dentists: dentists,
                    loading: false
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    onEditDentist = async (id) => {
        this.routeChange(config.MENU_ITEM_ADMIN_DENTISTS + "/" + id)
    }

    routeChange = (path) => {
        this.props.history.push(path);
    }

    handleAddDentistClick = () => {
        this.setState({
            add: !this.state.add
        });
    }

    render() {

        const add_dentist = this.state.add ? <Segment>
            <Form >
                <Form.Input required
                    label={strings.table_dentist_description} placeholder={strings.table_dentist_description}
                    onChange={this.handleChangeDescription} />
                <Form.Input required
                    label={strings.table_dentist_name} placeholder={strings.table_dentist_name}
                    onChange={this.handleChangeName} />
                <Form.Input required
                    label={strings.table_dentist_surname} placeholder={strings.table_dentist_surname}
                    onChange={this.handleChangeSurname} />
                <Form.Input required
                    label={strings.table_dentist_email} placeholder={strings.table_dentist_email}
                    onChange={this.handleChangeEmail} />
                <Form.Input required
                    label={strings.table_dentist_address} placeholder={strings.table_dentist_address}
                    onChange={this.handleChangeAddress} />
                <Form.Input required
                    label={strings.table_dentist_phone} placeholder={strings.table_dentist_phone}
                    onChange={this.handleChangePhone} />
                <Form.Input required
                    label={strings.table_dentist_password} placeholder={strings.table_dentist_password}
                    onChange={this.handleChangePassword} />
                <Button icon="save" color='green' content={strings.save_dentist_button} onClick={() => this.addDentist()} />
            </Form>

        </Segment> : null

        return (
            <div>

                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_ADMIN_DENTISTS} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>
                            <NavigationHeader title={strings.dentists_registry} action_button={true}
                                action_button_content={this.state.add ? strings.add_dentist_close_button : strings.add_dentist_button}
                                action_button_handleClick={() => this.handleAddDentistClick()}
                            >
                            </NavigationHeader>
                            <Loader className='custom-loader' active={this.state.loading} disabled={!this.state.loading} />
                            {add_dentist}
                            <DentistsTable
                                dentists={this.state.dentists}
                                onEditDentist={(id) => this.onEditDentist(id)}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>

            </div>
        );
    }

    handleChangeName = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.name = value;
        this.setState({ dentist: dentist });

    }

    handleChangeSurname = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.surname = value;
        this.setState({ dentist: dentist });

    }

    handleChangeDescription = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.description = value;
        this.setState({ dentist: dentist });

    }
    handleChangeEmail = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.email = value;
        this.setState({ dentist: dentist });

    }


    handleChangeAddress = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.address = value;
        this.setState({ dentist: dentist });

    }

    handleChangePhone = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.phone_number = value;
        this.setState({ dentist: dentist });

    }

    handleChangePassword = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.password = value;
        this.setState({ dentist: dentist });

    }

}

export default compose(
    withCookies,
    withRouter
)(DentistsRegistry)