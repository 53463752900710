import React, { Component } from 'react';
import { Grid, Segment, Button, Loader, Form } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import OperatorsTable from './OperatorsTable';


class OperatorsRegistry extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            operators: [],
            add: false,
            operator: {}
        };
    }


    componentDidMount() {
        this.getOperators();
    }

    addOperator = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_operator(this.state.accessToken, this.state.operator);
            if (response.data.data != null) {

                this.setState({
                    loading: false,
                    add: false
                });

                this.getOperators();

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    getOperators = async () => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_operators(this.state.accessToken, true);
            if (response.data.data != null) {
                const operators = [...response.data.data]
                //const pagination = Object.assign({}, response.data.pagination)
                this.setState({
                    operators: operators,
                    loading: false
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    onEditOperator = async (id) => {
        this.routeChange(config.MENU_ITEM_ADMIN_OPERATORS + "/" + id)
    }

    routeChange = (path) => {
        this.props.history.push(path);
    }

    handleAddDentistClick = () => {
        this.setState({
            add: !this.state.add
        });
    }

    render() {

        const add_operator = this.state.add ? <Segment>
            <Form >
                <Form.Checkbox label={strings.table_operator_inspector} onChange={this.handleChangeInspector} />
                <Form.Input required
                    label={strings.table_operator_name} placeholder={strings.table_operator_name}
                    onChange={this.handleChangeName} />

                <Form.Input required
                    label={strings.table_operator_surname} placeholder={strings.table_operator_surname}
                    onChange={this.handleChangeSurname} />

                <Form.Input required
                    label={strings.table_operator_lab} placeholder={strings.table_operator_lab}
                    onChange={this.handleChangeLab} />

                <Button icon="save" color='green' content={strings.save_operator_button} onClick={() => this.addOperator()} />
            </Form>

        </Segment> : null

        return (
            <div>

                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_ADMIN_OPERATORS} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>
                            <NavigationHeader title={strings.operators_registry}
                                action_button={true}
                                action_button_content={this.state.add ? strings.add_operator_close_button : strings.add_operator_button}
                                action_button_handleClick={() => this.handleAddDentistClick()}>
                            </NavigationHeader>

                            <Loader className='custom-loader' active={this.state.loading} disabled={!this.state.loading} />
                            {add_operator}
                            <OperatorsTable
                                operators={this.state.operators}
                                onEditOperator={(id) => this.onEditOperator(id)}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>

            </div>
        );
    }

    handleChangeName = (e, { value }) => {

        var operator = Object.assign({}, this.state.operator);
        operator.name = value;
        this.setState({ operator: operator });

    }

    handleChangeSurname = (e, { value }) => {

        var operator = Object.assign({}, this.state.operator);
        operator.surname = value;
        this.setState({ operator: operator });

    }

    handleChangeLab = (e, { value }) => {

        var operator = Object.assign({}, this.state.operator);
        operator.lab = value;
        this.setState({ operator: operator });

    }

    handleChangeInspector = (e, { checked }) => {

        var operator = Object.assign({}, this.state.operator);
        operator.inspector = checked;
        this.setState({ operator: operator });

    }


}

export default compose(
    withCookies,
    withRouter
)(OperatorsRegistry)