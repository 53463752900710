import React from 'react'
import { Button, Modal, Form, Segment, Icon, Divider } from 'semantic-ui-react'
import { strings } from '../core/strings';
import {
    DateInput
} from 'semantic-ui-calendar-react-17';

const paymentOptions = [
    { key: 'paid', text: strings.paid, value: 1 },
    { key: 'free', text: strings.free, value: 0 },
]

function TaskWorksModal(props) {
    const [open, setOpen] = React.useState(false)

    const onClick = (id_task) => {
        props.onSave(id_task);
        setOpen(false);
    }

    var works = props.current_works != null ? props.current_works.map(w => {

        const index = props.works.map(e => e.value).indexOf(w.id_work);

        return (
            <div>
                <Form.Group>
                    <Form.Input
                        label={strings.code}
                        width={6}
                        value={props.works[index].text}
                    />
                    <Form.Input
                        label={strings.quantity}
                        id_work={w.id_work}
                        quantity={w.quantity}
                        payment={w.payment}
                        discount={w.discount}
                        width={2}
                        defaultValue={1}
                        value={w.quantity}
                        selection
                        onChange={props.onChangeWorkQuantity}
                    />

                    <Form.Dropdown
                        width={4}
                        label={strings.payment_label}
                        id_work={w.id_work}
                        quantity={w.quantity}
                        payment={w.payment}
                        discount={w.discount}
                        placeholder={strings.payment_label}
                        selection
                        options={paymentOptions}
                        value={w.payment}
                        onChange={props.onChangeWorkPayment}
                    />

                    <Form.Input
                        label={strings.discount}
                        id_work={w.id_work}
                        quantity={w.quantity}
                        payment={w.payment}
                        discount={w.discount}
                        width={2}
                        defaultValue={0}
                        value={w.discount}
                        onChange={props.onChangeWorkDiscount}
                    />

                    <Form.Button
                        label="-" width={2} onClick={() => props.onDeleteWork(w)} icon color='red'>
                        <Icon name='trash alternate' />
                    </Form.Button>
                </Form.Group>
            </div>
        )
    }) : [];


    return (
        <Modal size='small'
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button color='teal' onClick={() => props.onChangeCurrentTask(props.task)} className='white-color'>{props.action_button_content != undefined ? props.action_button_content : props.number + " " + strings.edit_work_text_button}</Button>}
        >
            <Modal.Header>{props.task.type} </Modal.Header>

            <Modal.Content >
                <Form >
                <h3>{strings.works_list}</h3>
                    {works}
                    <Divider></Divider>
                    <Form.Dropdown width={6} search
                        label={strings.add_work}
                        placeholder={strings.add_work}
                        selection
                        options={props.works}
                        onChange={props.onAddNewWork}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button className='secondary-color-background' onClick={() => setOpen(false)}>
                    {strings.message_action_giveup}
                </Button>
                <Button
                    content={strings.save_user_button}
                    labelPosition='right'
                    icon='save'
                    onClick={() => onClick(props.task.id_task)}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default TaskWorksModal
