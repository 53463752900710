import React, { Component } from 'react';
import { Grid, Segment, Button, Loader, Form } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import UsersTable from './UsersTable';


const roleOptions = [{
    key: 'admin',
    text: strings.role_admin,
    value: 'admin',
}, {
    key: 'secretary',
    text: strings.role_secretary,
    value: 'secretary',
}]

class UsersRegistry extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            users: [],
            add: false,
            user: {}
        };
    }


    componentDidMount() {
        this.getUsers();
    }

    addUser = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_user(this.state.accessToken, this.state.user);
            if (response.data.data != null) {

                this.setState({
                    loading: false,
                    add: false
                });

                this.getUsers();

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    getUsers = async () => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_users(this.state.accessToken);
            if (response.data.data != null) {
                const users = [...response.data.data]
                //const pagination = Object.assign({}, response.data.pagination)
                console.log(users);
                this.setState({
                    users: users,
                    loading: false
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    onEditUser = async (id) => {
        this.routeChange(config.MENU_ITEM_ADMIN_USERS + "/" + id)
    }

    routeChange = (path) => {
        this.props.history.push(path);
    }

    handleAddDentistClick = () => {
        this.setState({
            add: !this.state.add
        });
    }

    render() {

        const add_user = this.state.add ? <Segment>
            <Form >
                <Form.Input required
                    label={strings.table_user_email} placeholder={strings.table_user_email}
                    onChange={this.handleChangeEmail} />
                <Form.Input required
                    label={strings.table_user_name} placeholder={strings.table_user_name}
                    onChange={this.handleChangeName} />
                <Form.Input required
                    label={strings.table_user_surname} placeholder={strings.table_user_surname}
                    onChange={this.handleChangeSurname} />
                <Form.Dropdown required
                    value={this.state.user !== null ? this.state.user.role : ''}
                    label={strings.table_user_role} placeholder={strings.table_user_role} selection options={roleOptions}
                    onChange={this.handleChangeRole} />
                <Form.Input required
                    label={strings.table_user_password} placeholder={strings.table_user_password}
                    onChange={this.handleChangePassword} />
                <Button icon="save" color='green' content={strings.save_user_button} onClick={() => this.addUser()} />
            </Form>

        </Segment> : null

        return (
            <div>

                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_ADMIN_USERS} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>
                            <NavigationHeader title={strings.users_registry}
                                action_button={true}
                                action_button_content={this.state.add ? strings.add_user_close_button : strings.add_user_button}
                                action_button_handleClick={() => this.handleAddDentistClick()}
                            >
                            </NavigationHeader>
                            <Loader className='custom-loader' active={this.state.loading} disabled={!this.state.loading} />
                            {add_user}
                            <UsersTable
                                users={this.state.users}
                                onEditUser={(id) => this.onEditUser(id)}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>

            </div>
        );
    }

    handleChangeName = (e, { value }) => {

        var user = Object.assign({}, this.state.user);
        user.name = value;
        this.setState({ user: user });

    }

    handleChangeSurname = (e, { value }) => {

        var user = Object.assign({}, this.state.user);
        user.surname = value;
        this.setState({ user: user });

    }

    handleChangeEmail = (e, { value }) => {

        var user = Object.assign({}, this.state.user);
        user.email = value;
        this.setState({ user: user });

    }
    handleChangeRole = (e, { value }) => {

        var user = Object.assign({}, this.state.user);
        user.role = value;
        this.setState({ user: user });

    }

    handleChangePassword = (e, { value }) => {

        var user = Object.assign({}, this.state.user);
        user.password = value;
        this.setState({ user: user });

    }

}

export default compose(
    withCookies,
    withRouter
)(UsersRegistry)