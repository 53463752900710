import React, { Component } from 'react';
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import "../App.css";
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import Root from './Root';
import User from './User';
import Prescriptions from './Prescriptions';
import Prescription from './Prescription';
import * as config from '../Config';
import DentistsRegistry from './DentistsRegistry';
import Dentist from './Dentist';
import UsersRegistry from './UsersRegistry';
import UserReg from './UserReg';
import OperatorsRegistry from './OperatorsRegistry';
import Operator from './Operator';
import Accounting from './Accounting';
import Dashboard from './Dashboard';
import Material from './Material';
import Work from './Work';
import MaterialsRegistry from './MaterialsRegistry';
import WorkslistRegistry from './WorkslistRegistry';

class Home extends Component {
    renderPrescription = (routerProps) => {
        let prescriptionId = routerProps.match.params.prescriptionId
        return <Prescription prescriptionId={prescriptionId} />
    }

    renderDentist = (routerProps) => {
        let dentistId = routerProps.match.params.dentistId
        return <Dentist dentistId={dentistId} />
    }

    renderUser = (routerProps) => {
        let userId = routerProps.match.params.userId
        return <UserReg userId={userId} />
    }

    renderOperator = (routerProps) => {
        let operatorId = routerProps.match.params.operatorId
        return <Operator operatorId={operatorId} />
    }

    renderMaterial = (routerProps) => {
        let materialId = routerProps.match.params.materialId
        return <Material materialId={materialId} />
    }

    renderWork = (routerProps) => {
        let workId = routerProps.match.params.workId
        return <Work workId={workId} />
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path={"/" + config.MENU_ITEM_USER}>
                        <User />
                    </Route>
                    <Route exact path={"/" + config.MENU_ITEM_ADMIN_DENTISTS + "/:dentistId"} render={this.renderDentist} ></Route>
                    <Route path={"/" + config.MENU_ITEM_ADMIN_DENTISTS}>
                        <DentistsRegistry />
                    </Route>

                    <Route exact path={"/" + config.MENU_ITEM_ADMIN_OPERATORS + "/:operatorId"} render={this.renderOperator} ></Route>
                    <Route path={"/" + config.MENU_ITEM_ADMIN_OPERATORS}>
                        <OperatorsRegistry />
                    </Route>
                    <Route exact path={"/" + config.MENU_ITEM_ADMIN_USERS + "/:userId"} render={this.renderUser} ></Route>
                    <Route path={"/" + config.MENU_ITEM_ADMIN_USERS}>
                        <UsersRegistry />
                    </Route>
                    <Route exact path={"/" + config.MENU_ITEM_PRESCRIPTIONS + "/:prescriptionId"} render={this.renderPrescription} ></Route>
                    <Route path={"/" + config.MENU_ITEM_PRESCRIPTIONS}>
                        <Prescriptions />
                    </Route>

                    <Route path={"/" + config.MENU_ITEM_ACCOUNTING}>
                        <Accounting />
                    </Route>

                    <Route path={"/" + config.MENU_ITEM_DASHBOARD}>
                        <Dashboard />
                    </Route>

                    <Route exact path={"/" + config.MENU_ITEM_ADMIN_MATERIALS + "/:materialId"} render={this.renderMaterial} ></Route>
                    <Route path={"/" + config.MENU_ITEM_ADMIN_MATERIALS}>
                        <MaterialsRegistry />
                    </Route>

                    <Route exact path={"/" + config.MENU_ITEM_WORKSLIST + "/:workId"} render={this.renderWork} ></Route>
                    <Route path={"/" + config.MENU_ITEM_WORKSLIST}>
                        <WorkslistRegistry />
                    </Route>

                    <Route path="/">
                        <Root />
                    </Route>

                </Switch>
            </Router>
        );
    }
}

export default compose(
    withCookies
)(Home)