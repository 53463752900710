import React from 'react'
import { Divider, Grid, Header, Button } from 'semantic-ui-react'
import "../App.css";

const NavigationHeader = (props) => (
    <div>
    <Grid columns={3}>
        <Grid.Row  >
            <Grid.Column textAlign='left' verticalAlign='middle'>
                <Header textAlign='left' as='h2'>{props.title} </Header>
            </Grid.Column>
            <Grid.Column verticalAlign='middle' >
            </Grid.Column>
            <Grid.Column textAlign='right' verticalAlign='middle' >
                {props.action_button?<Button content={props.action_button_content} onClick={props.action_button_handleClick} className='third-color-background white-color'/> :null}
            </Grid.Column>
        </Grid.Row>
    </Grid>
    <Divider></Divider>
    </div>
)

export default NavigationHeader