import React from 'react'
import { Button, Modal, Form, Grid, Image } from 'semantic-ui-react'
import { strings } from '../core/strings';
import CanvasModal from './CanvasModal';
import dateTimeFormatter from '../utils/dateTimeFormatter';
import logo from '../assets/logo-header.png'
import '../print.css';

var QRCode = require('qrcode.react');

function PrintModal(props) {
    const [open, setOpen] = React.useState(false)

    const onClick = () => {
        props.onEdit();
        setOpen(false);
    }

    return (
        <Modal className='custom'
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button fluid icon='print' ></Button>}
        >
            <Modal.Header>
                <Grid columns={2}>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column width={7} textAlign='right'>
                            <Image size='tiny' src={logo} />
                        </Grid.Column>
                        <Grid.Column width={9} textAlign='left'>
                            {strings.title_prescription + " " + props.prescription.id_prescription}
                        </Grid.Column>

                    </Grid.Row>
                </Grid>


            </Modal.Header>

            <Modal.Content >
                <Form >
                    <Grid verticalAlign='middle' columns={2} divided >
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Input size="big"
                                    value={props.prescription.box_number}
                                    label={strings.box_number} placeholder={strings.box_number}
                                />
                                <Form.Input size="big"
                                    value={props.prescription.dentist!=null?props.prescription.dentist.name + " " + props.prescription.dentist.surname:""}
                                    label={strings.table_dentist_description} placeholder={strings.table_dentist_description}
                                />
                                <Form.Input size="big"
                                    value={props.prescription.patient_code}
                                    label={strings.table_cod_patient} placeholder={strings.table_cod_patient}
                                />
                                <Form.Input size="big"
                                    value={props.prescription.date_start_work}
                                    label={strings.start_work} placeholder={strings.start_work}
                                />

                                <Form.Input size="big"
                                    value={dateTimeFormatter(props.prescription.date_internal)}
                                    label={strings.table_prescription_date_internal} placeholder={strings.table_prescription_date_internal}

                                />
                            </Grid.Column>
                            <Grid.Column>
                                <div style={{ textAlign: 'center' }}>
                                    <QRCode size='128' value={props.prescription.qr_code} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.TextArea rows={6}
                                    value={props.prescription.description}
                                    label={strings.table_prescription_description} placeholder={strings.table_prescription_description}

                                />
                                <Form.Group widths='equal'>
                                <Form.Input
                                    value={props.prescription.patient_gender}
                                    label={strings.patient_gender} placeholder={strings.patient_gender}
                                />
                                <Form.Input
                                    value={props.prescription.patient_age}
                                    label={strings.patient_age} placeholder={strings.patient_age}
                                />
                                </Form.Group>

                            </Grid.Column>
                            <Grid.Column>
                                <div style={{ textAlign: 'center' }}>
                                    <CanvasModal />
                                </div>

                                <Form.Input
                                    value={props.prescription.tint}
                                    label={strings.color_label} placeholder={strings.color_label}

                                />
                                <Form.Dropdown
                                    label={strings.materials_label}
                                    clearable
                                    fluid
                                    multiple
                                    search
                                    selection
                                    value={props.prescription.materials}
                                    options={props.materials}
                                    placeholder={strings.materials_add_label}

                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button className='secondary-color-background' onClick={() => setOpen(false)}>
                    {strings.back}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default PrintModal
