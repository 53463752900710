import React, { Component } from 'react';
import { compose } from 'recompose';
import { withCookies, Cookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import tokenCookie from '../core/tokenCookie';
import * as config from '../Config';
import API from '../core/api';
import { Grid, Segment, Form, Image, Button, Message } from 'semantic-ui-react';
import logo from '../assets/logo-header.png'
import { strings } from '../core/strings';

class Root extends Component {

    constructor(props) {
        super(props);

        const authToken = tokenCookie(props);

        this.state = {
            authToken: authToken,
            loading: false,
            error: false,
            email: '',
            password: ''
        };
    }

    routeChange = (path) => {
        this.props.history.push("/" + path);
    }

    setDentistAuthCookie(value) {
        const { cookies } = this.props;
        cookies.set('USER_TOKEN', value, { path: '/' });
    }

    componentDidMount() {
        if (this.state.authToken != null) {
            this.loginToken();
        }
    }

    onEmailChange = (e, { value }) => {
        this.setState({ email: value });
    }

    onPasswordChange = (e, { value }) => {
        this.setState({ password: value });
    }


    reset_password = async () => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.reset_password(this.state.email);
            if (response.data != null) {
                this.setState({
                    loading: false
                });
                alert("Password di recupero inviata con successo. Controlla la tua email per completare l'operazione.");
            } else {
                this.setState({
                    loading: false
                });
                alert("ko");
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    login = async () => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.login(this.state.email, this.state.password);
            if (response.data.data != null) {
                this.setState({
                    loading: false
                });
                this.setDentistAuthCookie(response.data.token);
                this.routeChange(config.MENU_ITEM_PRESCRIPTIONS);
            } else {
                this.setState({
                    loading: false,
                    error:true
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }


    render() {

        return (
            <div>
                <Grid centered style={{ height: '100vh' }} verticalAlign='middle'>
                    <Grid.Row columns={1}>
                        <Grid.Column style={{ maxWidth: 450 }}>
                            <Form loading={this.state.loading} >
                                <Segment textAlign='center' stacked>
                                    <Image centered size='small' src={logo} />

                                    <br />
                                    <Form.Input fluid icon='user' iconPosition='left' placeholder='Email' onChange={this.onEmailChange} />
                                    <Form.Input fluid
                                        icon='lock'
                                        iconPosition='left'
                                        placeholder='Password'
                                        type='password'
                                        onChange={this.onPasswordChange}
                                    />
                                    <Button className='secondary-color-background' fluid size='large' onClick={this.login}>{strings.login}</Button>
                                    <p></p>
                                    <Button className='trasparent-button' basic fluid onClick={this.reset_password}>{strings.password_forgotten_message}</Button>

                                </Segment>
                            </Form>
                            <Message
                                hidden={this.state.error ? false : true}
                                color='red'
                                header="Le credenziali non sono corrette"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    loginToken = async () => {
        this.setState({
            loading: true
        })
        try {
            let response = await API.loginToken(this.state.authToken);

            if (response.data.data != null) {
                const loginData = response.data.data

                if (loginData.id_user != null) {
                    this.routeChange(config.MENU_ITEM_PRESCRIPTIONS)
                } else {
                    const { cookies } = this.props;
                    cookies.remove('USER_TOKEN');

                    this.setState({
                        authToken: null,
                        loading: false
                    })
                }
            } else {
                this.setState({
                    authToken: null,
                    loading: false
                })
            }
        } catch (error) {
            this.setState({
                authToken: null,
                loading: false
            })
        }
    }
}

export default compose(
    withCookies,
    withRouter
)(Root)