import React, { Component } from 'react';
import { Grid, Segment, Loader, Header, Divider, Label } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import * as config from '../Config';
import TaskModal from './TaskModal';
import PlanningTasks from './PlanningTasks';
import dateTimeFormatter from '../utils/dateTimeFormatter';


class Planning extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            operators: [],
            works: [],
            add: false,
            operator: {},
            tasks: props.tasks,
            current_completed: false,
            current_inspector: false,
            current_id_operator: null,
            current_works: props.tasks.works,
            current_type: '',
            current_date: '',
            modalOpen: null,
            new_current_completed: false,
            new_current_inspector: false,
            new_current_id_operator: null,
            new_current_type: '',
            new_current_date: null,
            new_modalOpen: null,
        };

    }


    componentDidMount() {

        this.getOperators();
        this.getWorks();
    }

    getWorks = async () => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_workslist(this.state.accessToken);
            if (response.data.data != null) {
                const works = [...response.data.data].map(o => {
                    o.key = o.id_work;
                    o.text = o.code + ' - ' + o.name_fr;
                    o.value = o.id_work;
                    return (o)
                })

                //const pagination = Object.assign({}, response.data.pagination)
                this.setState({
                    works: works,
                    loading: false,
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }


    getOperators = async () => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_operators(this.state.accessToken);
            if (response.data.data != null) {
                const operators = [...response.data.data].map(o => {
                    o.key = o.id_operator;
                    o.text = o.name + " " + o.surname;
                    o.value = o.id_operator;
                    o.image = { avatar: true, src: o.url_image, style: { width: "30px", height: "30px", objectFit: 'cover' } };
                    return (o)
                })

                //const pagination = Object.assign({}, response.data.pagination)
                this.setState({
                    operators: operators,
                    loading: false
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    editTask = async (id) => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.put_task(
                this.state.accessToken,
                this.state.current_task_id,
                this.state.current_id_operator,
                this.state.current_type,
                this.state.current_completed,
                this.state.current_inspector,
                dateTimeFormatter(this.state.current_date)
            );
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                    modalOpen: false
                });
                this.getTasks();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    saveTaskWorks = async (id_task) => {
        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_task_works(
                this.state.accessToken,
                id_task,
                this.state.current_works
            );
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                    new_modalOpen: false
                });
                this.getTasks();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }

    }

    deleteTask = async (id_task) => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.delete_task(this.state.accessToken, id_task);
            if (response.data.data != null) {
                this.setState({
                    loading: false
                });
                this.getTasks();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            alert(error);
            this.setState({
                loading: false
            });
        }
    }

    saveTask = async () => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.post_task(
                this.state.accessToken,
                this.props.prescriptionId,
                this.state.new_current_id_operator,
                this.state.new_current_type,
                this.state.new_current_completed,
                this.state.new_current_inspector,
                dateTimeFormatter(this.state.new_current_date)
            );
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                    new_modalOpen: false
                });
                this.getTasks();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    getTasks = async () => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_tasks(this.state.accessToken, this.props.prescriptionId);
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                    tasks: [...response.data.data]
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    onEditOperator = async (id) => {
        this.routeChange(config.MENU_ITEM_ADMIN_OPERATORS + "/" + id)
    }

    routeChange = (path) => {
        this.props.history.push(path);
    }

    handleAddDentistClick = () => {
        this.setState({
            add: !this.state.add
        });
    }

    hadleChangeCurrentIdOperator = (e, { value }) => {
        this.setState({ current_id_operator: value });
    }
    hadleChangeCurrentCompleted = (e, { checked }) => {
        this.setState({ current_completed: checked });
    }
    hadleChangeCurrentType = (e, { value }) => {
        this.setState({ current_type: value });
    }

    handleChangeCurrentDate = (e, { value }) => {
        this.setState({ current_date: value });
    }

    handleChangeCurrentInspector = (e, { checked }) => {
        this.setState({ current_inspector: checked });
    }

    hadleChangeCurrentTask = (task) => {

        this.setState({
            current_task_id: task.id_task,
            current_completed: task.completed,
            current_id_operator: task.id_operator,
            current_type: task.type,
            current_inspector: task.inspector,
            current_date: dateTimeFormatter(task.date_assignment),
            current_works: task.works
        });
    }

    handleChangeNewCurrentIdOperator = (e, { value }) => {
        this.setState({ new_current_id_operator: value });
    }
    handleChangeNewCurrentCompleted = (e, { checked }) => {
        this.setState({ new_current_completed: checked });
    }
    handleChangeNewCurrentType = (e, { value }) => {
        this.setState({ new_current_type: value });
    }

    handleChangeNewCurrentInspector = (e, { checked }) => {
        this.setState({ new_current_inspector: checked });
    }

    handleChangeNewCurrentDate = (e, { value }) => {
        this.setState({ new_current_date: value });
    }

    handleChangeNewCurrentTask = (task) => {
        this.setState({
            new_current_completed: task.completed,
            new_current_id_operator: task.id_operator,
            new_current_type: task.type,
            new_current_date: task.date_assignment,
            new_current_inspector: task.inspector,
        });
    }

    handleChangeCurrentWork = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            id_work: data.value,
            quantity: data.quantity,
            discount: data.discount,
            payment: data.payment
        }
        const result = current_works.findIndex(element => element.id_work == el.id_work);

        current_works[result] = el

        this.setState({
            current_works: current_works,
        });
    }

    handleAddNewCurrentWork = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            id_work: data.value,
            quantity: 1,
            discount: 0,
            payment: 1
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);
        if (result === -1) {
            current_works.push(el)

        }

        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentWorkQuantity = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            quantity: data.value,
            id_work: data.id_work,
            discount: data.discount,
            payment: data.payment
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);

        current_works[result] = el


        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentWorkPayment = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            quantity: data.quantity,
            id_work: data.id_work,
            discount: data.discount,
            payment: data.value
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);

        current_works[result] = el


        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentWorkDiscount = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            quantity: data.quantity,
            id_work: data.id_work,
            discount: data.value,
            payment: data.payment
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);

        current_works[result] = el


        this.setState({
            current_works: current_works,
        });
    }

    cutExistingWork = (id_work) => {
        var works = [...this.state.works];
        const r = works.findIndex(element => element.value == id_work)
        if (r !== -1) {
            works.splice(r, 1);
        }
        return works;
    }

    handleDeleteCurrentWork = (w) => {

        var current_works = [...this.state.current_works];

        const result = current_works.findIndex(element => element.id_work == w.id_work);
        if (result === -1) {
        } else {
            current_works.splice(result, 1);

        }
        this.setState({ current_works: current_works });

    }

    render() {
        const tasks = this.state.tasks != undefined && this.state.tasks.length != 0 ? this.state.tasks : this.props.tasks;
        return (
            <div>
                {this.customNavigationHeader(strings.planning_title, true, this.state.add ? strings.add_operator_close_button : strings.add_operator_button)}
                <Grid stackable columns={2}>
                    <Grid.Row>
                        <Loader size='small' className='custom-loader' active={this.state.loading} disabled={!this.state.loading} />
                        <Grid.Column width={16}>
                            <Segment.Group raised>
                                <PlanningTasks
                                    onChangeCurrentIdOperator={this.hadleChangeCurrentIdOperator}
                                    onChangeCurrentCompleted={this.hadleChangeCurrentCompleted}
                                    onChangeCurrentInspector={this.handleChangeCurrentInspector}
                                    onChangeCurrentType={this.hadleChangeCurrentType}
                                    onChangeCurrentTask={this.hadleChangeCurrentTask}
                                    onChangeCurrentDate={this.handleChangeCurrentDate}
                                    current_date={this.state.current_date}
                                    onEdit={() => this.editTask()}
                                    onSaveTaskWorks={(id) => this.saveTaskWorks(id)}
                                    onDelete={(id) => this.deleteTask(id)}
                                    operators={this.state.operators}
                                    tasks={tasks}
                                    modalOpen={this.state.modalOpen}
                                    works={this.state.works}
                                    onChangeWork={this.handleChangeCurrentWork}
                                    onAddNewWork={this.handleAddNewCurrentWork}
                                    onChangeWorkQuantity={this.handleChangeCurrentWorkQuantity}
                                    onChangeWorkDiscount={this.handleChangeCurrentWorkDiscount}
                                    onChangeWorkPayment={this.handleChangeCurrentWorkPayment}
                                    current_works={this.state.current_works}
                                    onDeleteWork={(id) => this.handleDeleteCurrentWork(id)}
                                />
                            </Segment.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    customNavigationHeader = (title, action_button, action_button_content) => {
        return (
            <div>
                <Grid columns={3}>
                    <Grid.Row  >
                        <Grid.Column textAlign='left' verticalAlign='middle'>
                            <Header textAlign='left' as='h2'>{title} </Header>
                        </Grid.Column>
                        <Grid.Column textAlign='center' verticalAlign='middle' >
                            <Label color='red' size='big'>
                                {strings.table_prescription_date_internal}
                                <Label.Detail> {dateTimeFormatter(this.props.date_internal)}</Label.Detail>
                            </Label>
                        </Grid.Column>
                        <Grid.Column textAlign='right' verticalAlign='middle' >

                            {action_button ? <TaskModal
                                action_button_content={action_button_content}
                                operators={this.state.operators}
                                onEdit={() => this.saveTask()}
                                onChangeCurrentIdOperator={this.handleChangeNewCurrentIdOperator}
                                onChangeCurrentCompleted={this.handleChangeNewCurrentCompleted}
                                onChangeCurrentType={this.handleChangeNewCurrentType}
                                onChangeCurrentTask={this.handleChangeNewCurrentTask}
                                onChangeCurrentInspector={this.handleChangeNewCurrentInspector}
                                onChangeCurrentDate={this.handleChangeNewCurrentDate}
                                current_date={this.state.new_current_date}
                                works={this.state.works}
                                task={{
                                    id_operator: 0,
                                    completed: false,
                                    type: '',
                                    inspector: false,
                                    works: []
                                }}
                                open={this.state.new_modalOpen}
                            /> : null}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider></Divider>
            </div>)
    }
}

export default compose(
    withCookies,
    withRouter
)(Planning)