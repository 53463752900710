import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    accounting: 'Accounting',
    today: 'Today',
    prescriptions: "Prescriptions",
    back: 'back',
    user: "Admin",
    new_prescription: "Add prescription",
    logout: "Logout",
    table_id_prescription: "Code",
    table_id_prescription2: "Prescription code",
    table_cod_patient: "Patient code",
    table_prescription_description: "Description",
    table_prescription_date_assigment:"Date task",
    table_prescription_time_requested: "Hour",
    table_prescription_date_requested: "Date ",
    table_prescription_date_requested2: "requested",
    table_prescription_date_internal: "Data di fine lavori",
    table_prescription_status: "Status",
    table_prescription_datetime: "Datetime",
    table_prescription_datetime2: "",
    table_dentist_id: "Id",
    table_dentist_email: "Email",
    table_dentist_name: "Name",
    table_dentist_surname: "Surname",
    table_dentist_description: "Description",
    table_dentist_address: "Address",
    table_dentist_password: "Password provvisoria",
    table_dentist_phone: "Phone",
    table_user_id: "Id",
    table_user_email: "Email",
    table_user_name: "Name",
    table_user_surname: "Surname",
    table_user_role: "Role",
    table_user_password: "Password",
    table_operator_id: "Id",
    table_operator_image: "Image",
    table_operator_lab: "Lab",
    table_operator_name: "Name",
    table_operator_surname: "Surname",
    table_operator_inspector: "Inspector",
    table_operator_hidden: "Hidden",
    status_preparing: "Preparing",
    status_preparing_description: "Working program is not already defined",
    status_progress: "Working",
    status_progress_description: "Working in progress. Check the progress bar!",
    status_completed: "Completed",
    status_completed_description: "Working process is completed. We are going to deliver soon",
    status_shipped: "Shipped",
    status_shipped_description: "Package is delivered",
    progress_title: "Working Progress",
    progress_prescription: "Stato di avanzamento",
    ref_prev_prescription: "Related prescription code",
    go_text_button: "Go",
    edit_text_button: "Edit",
    edit_task_title: "Edit Task",
    report_text_button: "Report",
    new_task_title: "Create Task",
    materials_label: "Materials",
    materials_add_label: "Add materials",
    color_label: "Color",
    images_button_label: "Open images",
    images_button_label_close: "Close images",
    upload_new_image_label: "Upload new image",
    upload_new_file_label: "Upload new file",
    support: "Tech support",
    admin: "Studio",
    new_password: "Nuova password",
    confirm_new_password: "Nuova password",
    old_password: "Vecchia password",
    save_new_password: "Salva modifica",
    owner: "Owner",
    email: "Email",
    email: "Email",
    role: "Role",
    phone: "Phone",
    address: "Address",
    title_prescription: "Prescrizione n°",
    title_new_prescription: "New prescription",
    send_prescription_button_label: "Send prescription",
    save_prescription_button_label: "Save prescription",
    cancel_prescription_button_label: "Cancel",
    action_prescription_button_label: "See",
    delete_prescription_button_label: "Delete",
    title_admin: "Administration",
    users_manager: "Users",
    dentists_manager: "Dentists",
    operators_manager: "Operators",
    edit_password_label: "Edit password",
    dentists_registry: "Dentists Registry",
    dentist_title: "Dentist id:",
    add_dentist_button: "Add dentist",
    add_dentist_close_button: "Close",
    save_dentist_button: "Save",
    users_registry: "Registro utenti interni",
    add_user_button: "Aggiungi nuovo utente interno",
    add_user_close_button: "Annulla",
    user_title: "Utente id:",
    save_user_button: "Save",
    operators_registry: "Registro dentisti",
    operator_title: "Dentist id:",
    add_operator_button: "Aggiungi nuovo operatore",
    add_task_button: "Aggiungi nuovo task",
    add_operator_close_button: "Annulla",
    save_operator_button: "Save",
    role_secretary: 'secretaty',
    role_admin: 'admin',
    planning_title: "Planning",
    plan_title: "Plan",
    inspection_label: "Inspection",
    send_report_title: "Send report",
    message_error_standard: "An error occured. Please try later",
    message_error_wrong_credentials: "Old password is wrong!",
    message_action_ok: "OK",
    message_confirmation_standard: "Operation successfully completed",
    message_lose_data: "Are you sure to continue? You will lose all unsaved data",
    message_action_goon: "Go on!",
    message_action_giveup: "Cancel",
    message_password_not_equal: "The two version of new password are not equal",
    message_password_ok: "New password has been set correctly",
    paid: "Pay",
    free: "Free",
    payment_label: "Payment",
    ongoing: "Ongoing",
    login: "Accedi",
    password_forgotten_message: "If you forgot password, please click here to reset",
    start_datetime: "Start",
    end_datetime: "End",
    no_task: "No task",
    dashboard: "Dasboard",
    confirm: "Confirm",
    workslist: "Workslist",
    code: "Code",
    price: "Price",
    add_work: "Add work",
    work: "Work",
    work_personalisation: "Work personalisation",
    add_work_personalisation: "Add personalisation", 
    discount: "Discount",
    work_code: "Work Code",
    patient_age: "Patient Age",
    start_work: "Start work date",

    table_prescription_description_invoice: "Invoice description",
    modal_batch_number_title: "Add batch number",
    batch_number: "Materials CE",
    batch_number_modal: "Batch number",
    materials_label: "Materials",
    add_material_button: "Add material",
    table_material_name_it: 'Name (it)',
    table_material_name_en: 'Name (en)',
    table_material_name_fr: 'Name (fr)',
    table_material_type: 'Type',
    table_material_category: 'Category',
    table_material_classification: 'Classification',
    table_material_producer: 'Producer',
    table_material_ce: 'CE',
    table_material_norms: 'Norms',
    certificate:'Certificate',
    download: "Download",
    no_certificate: "No certificate.",
    patient_gender: "Patient gender",
    box_number: "Box number",
    edit_work_text_button: "Works",
    accounting: 'Accounting',
    work: "Work",
    quantity: "Quantity",
    quantity_abb: "Qty",
    batch_number_abb: "Num",
    price: "Price (€)",
    total: "Total",
    description: "Description",
    additional_costs: "Additional costs",
    works_list: "Works list",
    add_additinal_cost: "Add additional cost"
  },
  it: {
    accounting: 'Contabilità',
    today: 'Oggi',
    prescriptions: "Elenco prescrizioni",
    back: 'Indietro',
    user: "Profilo",
    new_prescription: "Richiedi prescrizione",
    logout: "Logout",
    table_id_prescription: "Codice",
    table_id_prescription2: "Codice prescrizione",
    table_cod_patient: "Codice paziente",
    table_prescription_description: "Descrizione",
    table_prescription_date_assigment:"Data attività",
    table_prescription_time_requested: "Ora",
    table_prescription_date_requested: "Data di consegna",
    table_prescription_date_requested2: "richiesta",
    table_prescription_date_internal: "Data di fine lavori",
    table_prescription_status: "Status",
    table_prescription_datetime: "Data/ora ",
    table_prescription_datetime2: "inoltro",
    table_dentist_id: "Id",
    table_dentist_email: "Email",
    table_dentist_name: "Nome",
    table_dentist_surname: "Cognome",
    table_dentist_description: "Dentista",
    table_dentist_address: "Indirizzo",
    table_dentist_password: "Password iniziale (dovrà essere modificata al primo accesso) ",
    table_dentist_phone: "Telefono",
    table_user_id: "Id",
    table_user_email: "Email",
    table_user_name: "Nome",
    table_user_surname: "Cognome",
    table_user_role: "Ruolo",
    table_user_password: "Password",
    table_operator_id: "Id",
    table_operator_image: "Immagine",
    table_operator_lab: "Laboratorio",
    table_operator_name: "Nome",
    table_operator_surname: "Cognome",
    table_operator_inspector: "Ispettore",
    table_operator_hidden: "Nascosto",
    status_preparing: "In preparazione",
    status_preparing_description: "A breve verrà definito il programma di lavoro",
    status_progress: "In lavorazione",
    status_progress_description: "La prescrizione è in lavorazione",
    status_completed: "Completato",
    status_completed_description: "La lavorazione è completata.",
    status_shipped: "Spedito",
    status_shipped_description: "Il pacco è stato consegnato al corriere",
    progress_title: "Progresso della lavorazione",
    progress_prescription: "Stato di avanzamento",
    ref_prev_prescription: "Codice prescrizione precedente",
    go_text_button: "Vai",
    edit_text_button: "Modifica",
    edit_task_title: "Modifica Task",
    new_task_title: "Crea nuovo Task",
    materials_label: "Materiali",
    materials_add_label: "Aggiungi materiali",
    color_label: "Colore",
    images_button_label: "Visualizza le immagini e file",
    images_button_label_close: "Nascondi le immagini e file",
    upload_new_image_label: "Carica nuova foto",
    upload_new_file_label: "Carica nuovo file",
    support: "Supporto tecnico",
    admin: "Studio",
    new_password: "Nuova password",
    confirm_new_password: "Ripeti nuova password",
    old_password: "Vecchia password",
    save_new_password: "Salva modifica",
    owner: "Titolare",
    email: "Email",
    email: "Email",
    role: "Ruolo",
    phone: "Telefono",
    address: "Indirizzo",
    title_prescription: "Prescrizione n°",
    title_new_prescription: "Nuova prescrizione",
    send_prescription_button_label: "Invia prescrizione",
    save_prescription_button_label: "Salva",
    cancel_prescription_button_label: "Annulla",
    action_prescription_button_label: "Entra",
    delete_prescription_button_label: "Elimina",
    title_admin: "Profilo utente",
    users_manager: "Gestione utenti",
    dentists_manager: "Gestione dentisti",
    operators_manager: "Gestione operatori",
    edit_password_label: "Modifica password",
    report_text_button: "Segnalazione",
    dentists_registry: "Registro dentisti",
    dentist_title: "Dentista id:",
    add_dentist_button: "Aggiungi nuovo dentista",
    add_dentist_close_button: "Annulla",
    save_dentist_button: "Salva",
    users_registry: "Registro utenti interni",
    add_user_button: "Aggiungi nuovo utente interno",
    add_user_close_button: "Annulla",
    user_title: "Utente id:",
    save_user_button: "Salva",
    operators_registry: "Registro operatori",
    operator_title: "Operatore id:",
    add_operator_button: "Aggiungi nuovo operatore",
    add_task_button: "Aggiungi nuovo task",
    add_operator_close_button: "Annulla",
    save_operator_button: "Salva",
    role_secretary: 'segreteria',
    role_admin: 'amministrazione',
    planning_title: "Programmazione",
    plan_title: "Programma",
    inspection_label: "Controllo",
    send_report_title: "Invia segnalazione al cliente",
    message_error_standard: "Si è verificato un errore. Riprovare più tardi. Se il problema persiste contattare il supporto tecnico.",
    message_error_wrong_credentials: "La vecchia password non è corretta.",
    message_action_ok: "OK",
    message_confirmation_standard: "Operazione avvenuta con successo.",
    message_lose_data: "Sei sicuro di continuare? Perderai tutti i dati non salvati.",
    message_action_goon: "Continua",
    message_action_giveup: "Annulla",
    message_password_not_equal: "Le due versioni della nuova password non coincidono.",
    message_password_ok: "La nuova password è stata settata correttamente.",
    paid: "Da pagare",
    free: "Non da pagare",
    payment_label: "Pagamento",
    ongoing: "In corso",
    login: "Accedi",
    password_forgotten_message: "Se hai dimenticato la password e vuoi resettarla clicca qui!",
    start_datetime: "Inizio",
    end_datetime: "Fine",
    no_task: "Non ci sono task assegnati",
    dashboard: "Dashboard",
    confirm: "Conferma",
    workslist: "Listino lavorazioni",
    code: "Codice",
    price: "Prezzo",
    add_work: "Aggiungi lavorazione",
    work: "Lavorazione",
    work_personalisation: "Personalizzazione listino", 
    add_work_personalisation: "Aggiungi personalizzazione", 
    discount: "Sconto",
    work_code: "Codice Lavorazione",
    patient_age: "Età paziente",
    start_work: "Data inizio lavori",


    table_prescription_description_invoice: "Descrizione in fattura",
    modal_batch_number_title: "Aggiungi numero lotto",
    batch_number: "Materiali CE",
    batch_number_modal: "Numero di lotto",
    materials_label: "Materiali",
    add_material_button: "Aggiungi materiale",
    table_material_name_it: 'Nome (it)',
    table_material_name_en: 'Nome (en)',
    table_material_name_fr: 'Nome (fr)',
    table_material_type: 'Tipologia',
    table_material_category: 'Categoria',
    table_material_classification: 'Classificatione',
    table_material_producer: 'Produttore',
    table_material_ce: 'CE',
    table_material_norms: 'Norma',
    certificate:'Certificato',
    download: "Scarica",
    no_certificate: "Il certificato è assente.",
    patient_gender: "Genere paziente",
    box_number: "Numero scatola",
    edit_work_text_button: "Lavorazioni",
    work: "Lavorazione",
    quantity: "Quantità",
    quantity_abb: "Qtà",
    batch_number_abb: "Lot",
    price: "Prezzo (€)",
    total: "Totale",
    description: "Descrizione",
    additional_costs: "Costi addizionali",
    works_list: "Elenco lavorazioni",
    add_additinal_cost: "Aggiungi costo addizionale"
  },
  fr: {
    accounting: 'Comptabilité',
    today: 'Aujourd’hui',
    prescriptions: "Liste de prescriptions",
    back: 'En arrière',
    user: "Profil",
    new_prescription: "Demande de prescription",
    logout: "Déconnexion",
    table_id_prescription: "Code",
    table_id_prescription2: "Code prescription",
    table_cod_patient: "Code patient",
    table_prescription_date_assigment:"Date de travail",
    table_prescription_description: "Description",
    table_prescription_time_requested: "Heure",
    table_prescription_date_requested: "Date de livraison",
    table_prescription_date_requested2: "Demande",
    table_prescription_date_internal: "Date de fin travail",
    table_prescription_status: "Statut",
    table_prescription_datetime: "Date/heure ",
    table_prescription_datetime2: "Tranfert",
    table_dentist_id: "Id",
    table_dentist_email: "Mail",
    table_dentist_name: "Prénom",
    table_dentist_surname: "Nom",
    table_dentist_description: "Dentiste",
    table_dentist_address: "Adresse",
    table_dentist_password: "Mot de passe initiale (devra être modifié au premier accès) ",
    table_dentist_phone: "Téléphone",
    table_user_id: "Id",
    table_user_email: "Mail",
    table_user_name: "Prénom",
    table_user_surname: "Nom",
    table_user_role: "Rôle",
    table_user_password: "Mot de passe",
    table_operator_id: "Id",
    table_operator_image: "Image",
    table_operator_lab: "Laboratoire",
    table_operator_name: "Prénom",
    table_operator_surname: "Nom",
    table_operator_inspector: "Contrôle",
    table_operator_hidden: "Caché",
    status_preparing: "En préparation",
    status_preparing_description: "Le programme de travail sera défini prochainement",
    status_progress: "En cours de travail",
    status_progress_description: "La prescription est en cours de traitement",
    status_completed: "Statut terminé",
    status_completed_description: "Le traitement est complété.",
    status_shipped: "Envoyé",
    status_shipped_description: "Le colis à été remis au coursier",
    progress_title: "Progression du traitement",
    progress_prescription: "Progression de prescription",
    ref_prev_prescription: "Code prescription précedente",
    go_text_button: "Suivant",
    edit_text_button: "Modifier",
    edit_task_title: "Modifier Tâche",
    new_task_title: "Créer nouvelle Tâche",
    materials_label: "Materiaux",
    materials_add_label: "Ajouter materiaux",
    color_label: "Teinte",
    images_button_label: "Visualiser les images et fichiers",
    images_button_label_close: "Cacher les images et fichiers",
    upload_new_image_label: "Charger nouvelle photo",
    upload_new_file_label: "Charger nouveau fichier",
    support: "Support technique",
    admin: "Cabinet",
    new_password: "Nouveau mot de passe",
    confirm_new_password: "Répété nouveau mot de passe",
    old_password: "Ancien mot de passe",
    save_new_password: "Sauvegarder modifications",
    owner: "Titulaire",
    email: "Mail",
    email: "Mail",
    role: "Rôle",
    phone: "Téléphone",
    address: "Adresse",
    title_prescription: "Prescription n°",
    title_new_prescription: "Nouvelle prescription",
    send_prescription_button_label: "Envoyer prescription",
    save_prescription_button_label: "Sauvegarder",
    cancel_prescription_button_label: "Annuler",
    action_prescription_button_label: "Entrer",
    delete_prescription_button_label: "Eliminer",
    title_admin: "Profil utilisateur",
    users_manager: "Gestion utilisateurs",
    dentists_manager: "Gestion praticiens",
    operators_manager: "Gestion operateurs",
    edit_password_label: "Modifier mot de passe",
    report_text_button: "Rapport",
    dentists_registry: "Registre praticiens",
    dentist_title: "Dentiste id:",
    add_dentist_button: "Ajouter nouveau praticiens",
    add_dentist_close_button: "Annuler",
    save_dentist_button: "Sauvegarder",
    users_registry: "Registre utilisateurs interne",
    add_user_button: "Ajouter nouvel utilisateur interne",
    add_user_close_button: "Annuler",
    user_title: "Id d’utilisateur:",
    save_user_button: "Sauvegarder",
    operators_registry: "Registre operateurs",
    operator_title: "Id operateur:",
    add_operator_button: "Ajouter nouvel operateur",
    add_task_button: "Ajouter nouvelle tâche",
    add_operator_close_button: "Annuler",
    save_operator_button: "Sauvegarder",
    role_secretary: 'Secrétariat',
    role_admin: 'Administration',
    planning_title: "Programmation",
    plan_title: "Programme",
    inspection_label: "Contrôle",
    send_report_title: "Envoyer rapport au client",
    message_error_standard: "Une erreur est survenue. Réessayez plus tard. Si le problème persiste, contactez le support technique.",
    message_error_wrong_credentials: "L’ancien mot de passe est incorrecte.",
    message_action_ok: "OK",
    message_confirmation_standard: "Operation terminé avec succès.",
    message_lose_data: "Êtes-vous sûr de vouloir continuer? Vous perdrez toutes les données non enregistrées.",
    message_action_goon: "Continuer",
    message_action_giveup: "Annuler",
    message_password_not_equal: "Les deux version du nouveau mot de passe ne correspondent pas.",
    message_password_ok: "Le nouveau mot de passe a été défini correctement.",
    paid: "À payer",
    free: "À ne pas payer",
    payment_label: "Paiement",
    ongoing: "En cours",
    login: "Acceder",
    password_forgotten_message: "Si vous avez oublié votre mot de passe et souhaitez le réinitialiser, cliquez ici !",
    start_datetime: "Démarrer",
    end_datetime: "Fin",
    no_task: "Il n’y a pas de tâches assignées",
    dashboard: "Tableau de bord",
    confirm: "Confirmation",
    workslist: "Tableau traitements",
    code: "Code",
    price: "Prix",
    add_work: "Ajouter traitement",
    work: "Traitement",
    work_personalisation: "Traitement personnalisation", 
    add_work_personalisation: "Ajouter personnalisation", 
    discount: "Réduction",
    work_code: "Code Traitement",
    patient_age: "Âge patient",
    start_work: "Date de début de travail",


    table_prescription_description_invoice: "Description en facture",
    modal_batch_number_title: "Ajouter le n° di lot",
    batch_number: "Matériaux CE",
    batch_number_modal: "Num di lot",
    materials_label: "Matériaux",
    add_material_button: "Ajouter du matière",
    table_material_name_it: 'Matière (it)',
    table_material_name_en: 'Matière (en)',
    table_material_name_fr: 'Matière (fr)',
    table_material_type: 'Type',
    table_material_category: 'Catégorie',
    table_material_classification: 'Classification',
    table_material_producer: 'Fabricant',
    table_material_ce: 'CE',
    table_material_norms: 'Norme',
    certificate:'Certificat',
    download:'Télécharger',
    no_certificate: "Le certificat est absent.",
    patient_gender: "Genre patient",
    box_number: "Numéro de boîte",
    edit_work_text_button: "Travails",
    work: "Travail",
    quantity: "Quantité",
    quantity_abb: "Qtè",
    batch_number_abb: "Lot",
    price: "Prix (€)",
    total: "Total",
    description: "Description",
    additional_costs: "Supplément",
    works_list: "Liste de travails",
    add_additinal_cost: "Ajouter supplément"
  }
})
export { strings };