import React from 'react'
import { Table, Icon, Button, Input, Segment, Menu, Image, Label } from 'semantic-ui-react'
import { strings } from '../core/strings';
import dateTimeFormatter from '../utils/dateTimeFormatter'
import moment from 'moment';

function groupBy(array, key) {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
    }, {});
}

const OperatorsProductivityTable = (props) => {

    const grouped = groupBy(props.data, 'date_assignment')
    const todayDate = moment().format('YYYY-MM-DD');
    const todayDateTime = todayDate + " 23:59:59";
    //console.log(grouped)
    var nrows = Object.entries(grouped).map(obj => {

        if (obj[0] <= todayDate) {
            const key = obj[0];
            const value = obj[1];

            var header = <Table.HeaderCell colspan='4'>{key != "null" ? dateTimeFormatter(key) : "Data non assegnata"} / Numero Task: {value.length}</Table.HeaderCell>

            var rows = value.map(p => {
                p.code = p.code != null ? p.code : "Non definito";
                p.name_fr = p.name_fr != null ? p.name_fr : "";


                return (
                    <Table.Row negative={p.end_datetime == null && p.date_assignment < todayDate} key={p.id_task}>
                        <Table.Cell>{"(" + p.id_prescription + ") " + p.description} </Table.Cell>
                        <Table.Cell>{p.code + " - " + p.name_fr}</Table.Cell>
                        <Table.Cell>{dateTimeFormatter(p.start_datetime)}</Table.Cell>
                        <Table.Cell>{dateTimeFormatter(p.end_datetime)}</Table.Cell>
                    </Table.Row>
                )
            });

            return (
                <Table celled fixed>
                    <Table.Header>
                        <Table.Row textAlign='left'>
                            {header}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {rows}
                    </Table.Body>
                </Table>
            )
        }

    });

    console.log(nrows)

    let prev, next, page;

    if (props.pagination) {

        if (props.pagination.prev) {
            prev =
                <Menu.Item onClick={() => props.changePage(props.pagination.prev)} as='a' icon>
                    <Icon name='chevron left' />
                </Menu.Item>
        }
        if (props.pagination.next) {
            next =
                <Menu.Item onClick={() => props.changePage(props.pagination.next)} as='a' icon>
                    <Icon name='chevron right' />
                </Menu.Item>
        }
    }

    return (
        <div>
            <Table celled fixed>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell >DESCRIZIONE</Table.HeaderCell>
                        <Table.HeaderCell >LISTINO</Table.HeaderCell>
                        <Table.HeaderCell >DATA INIZIO TASK</Table.HeaderCell>
                        <Table.HeaderCell >DATA FINE TASK</Table.HeaderCell>
                    </Table.Row>

                </Table.Header>
            </Table>

            {nrows}
            <Table>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell textAlign='center' colSpan="4">
                            <Menu floated='right' pagination>
                                {prev}{next}
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>

    );
}


export default (OperatorsProductivityTable);

