import * as config from '../Config';

const tokenCookie = (props) => {
    
    /*if(config.MODE === 'DEMO'){
        return 'abcdefg1';
    }*/

    const { cookies } = (props != null ? props : {});

    const accessToken = cookies.get('USER_TOKEN', true);

    return accessToken;

}

export default tokenCookie;