const dateTimeFormatter = (datetimeStr) => {
    if (datetimeStr !== null & datetimeStr !== undefined) {
        let dateStr = datetimeStr.substring(0, 10);
        let timeStr = datetimeStr.substring(11, 16);

        let dArr = dateStr.split("-");
        return dArr[2] + "-" + dArr[1] + "-" + dArr[0].substring(0, 4) + " " + timeStr;
    } else {
        return datetimeStr;
    }
}


export default dateTimeFormatter;