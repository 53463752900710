import React from 'react'
import { Table, Icon, Button, Loader, Menu } from 'semantic-ui-react'
import { strings } from '../core/strings';

const status_label = [
    strings.status_preparing,
    strings.status_progress,
    strings.status_completed,
    strings.status_shipped
];

const status_label_color = [
    'grey',
    'yellow',
    'blue',
    'green'
];

const DentistsTable = (props) => {

    let rows = props.dentists.map(p => {
        return (
            <Table.Row textAlign='center' key={p.id_dentist}>
                <Table.Cell>{p.id_dentist} </Table.Cell>
                <Table.Cell>{p.email}</Table.Cell>
                <Table.Cell>{p.name}</Table.Cell>
                <Table.Cell>{p.surname}</Table.Cell>
                <Table.Cell>{p.description}</Table.Cell>
                <Table.Cell>{p.address}</Table.Cell>
                <Table.Cell>{p.phone_number}</Table.Cell>
                <Table.Cell>
                    <Button className='third-color-background white-color' 
                             onClick={() => props.onEditDentist(p.id_dentist)} >
                            <Icon name='edit outline'/>
                            {strings.edit_text_button}
                    </Button>
                </Table.Cell>
            </Table.Row>
        )
    });

    let prev, next, page;

    if (props.pagination) {

        if (props.pagination.prev) {
            prev =
                <Menu.Item onClick={() => props.changePage(props.pagination.prev)} as='a' icon>
                    <Icon name='chevron left' />
                </Menu.Item>
        }
        if (props.pagination.next) {
            next =
                <Menu.Item onClick={() => props.changePage(props.pagination.next)} as='a' icon>
                    <Icon name='chevron right' />
                </Menu.Item>
        }
    }

    return (
        <div>
            <Loader active={props.loading} />
            <Table sortable celled structured>
                <Table.Header>
                    <Table.Row textAlign='center'>
                        <Table.HeaderCell width='1' rowSpan='1'>{strings.table_dentist_id}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_dentist_email}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_dentist_name}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_dentist_surname}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_dentist_description}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_dentist_address}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_dentist_phone}</Table.HeaderCell>
                        <Table.HeaderCell style={{width:'150px'}} rowSpan='1'></Table.HeaderCell>
                    </Table.Row>

                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell textAlign='center' colSpan="11">
                            <Menu floated='right' pagination>
                                {prev}{next}
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>

    );
}


export default (DentistsTable);

