import React, { Component } from 'react';
import { Grid, Segment, Form, Button, Divider, Header } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import dateTimeFormatter from '../utils/dateTimeFormatter';


class Material extends Component {
    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            material: null,
            materials: [],
            loading: false
        }
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getMaterial(this.props.materialId);
    }


    getMaterial = async (materialId) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_material_certification(this.state.accessToken, materialId);
            if (response.data.data != null) {
                var material = response.data.data

                this.setState({
                    material: material,
                    loading: false
                });
            } else if (response.data.error.code == '23000') {
                const { cookies } = this.props;
                cookies.remove('USER_TOKEN');
                this.props.history.push("/");
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }


    goBack = () => {
        this.props.history.goBack();
    }


    saveButton = () => {
        this.saveMaterial();
    }


    saveMaterial = async () => {

        var material = Object.assign({}, this.state.material);

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_material(this.state.accessToken, material);

            if (response.data.data != null) {

                this.props.history.goBack();

            } else {
                this.setState({
                    loading: false
                });
            }

        } catch (error) {
            // TODO handle error
            alert("KO");
            this.setState({
                loading: false
            });
        }
    }

    handleChangeNameIt = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.name_it = value;
        this.setState({ material: material });

    }

    handleChangeNameEn = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.name_en = value;
        this.setState({ material: material });

    }

    handleChangeNameFr = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.name_fr = value;
        this.setState({ material: material });

    }


    handleChangeType = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.type = value;
        this.setState({ material: material });

    }

    handleChangeCategory = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.category = value;
        this.setState({ material: material });

    }
    handleChangeClassification = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.classification = value;
        this.setState({ material: material });

    }


    handleChangeProducer = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.producer = value;
        this.setState({ material: material });

    }

    handleChangeCe = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.ce = value;
        this.setState({ material: material });

    }

    handleChangeNorms = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.norms = value;
        this.setState({ material: material });

    }

    handleChangeBatchNumber = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.batch_number = value;
        this.setState({ material: material });

    }


    cancelEditButton = () => {
        this.getMaterial(this.state.material.id_material);
    }

    render() {

        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_ADMIN_MATERIALS} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>

                            <Grid columns={3}>
                                <Grid.Row >
                                    <Grid.Column textAlign='left' verticalAlign='middle' width={5}>
                                        <Button className='third-color-background white-color' content={strings.back} onClick={() => this.goBack()} />
                                    </Grid.Column>
                                    <Grid.Column textAlign='center' verticalAlign='middle' width={6}>
                                        <Header as='h2'> {strings.materials_label}</Header>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right' verticalAlign='middle' width={5}>
                                        <Button className='secondary-color-background white-color' content={strings.cancel_prescription_button_label} onClick={() => this.cancelEditButton()} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider />
                            <Form loading={this.state.loading} error={this.incomplete_submit} >
                                <Form.Input value={this.state.material !== null ? this.state.material.name_it : ''}
                                    label={strings.table_material_name_it} placeholder={strings.table_material_name_it}
                                    onChange={this.handleChangeNameIt} />
                                <Form.Input value={this.state.material !== null ? this.state.material.name_en : ''}
                                    label={strings.table_material_name_en} placeholder={strings.table_material_name_en}
                                    onChange={this.handleChangeNameEn} />
                                <Form.Input value={this.state.material !== null ? this.state.material.name_fr : ''}
                                    label={strings.table_material_name_fr} placeholder={strings.table_material_name_fr}
                                    onChange={this.handleChangeNameFr} />
                                <Form.Input value={this.state.material !== null ? this.state.material.type : ''}
                                    label={strings.table_material_type} placeholder={strings.table_material_type}
                                    onChange={this.handleChangeType} />
                                <Form.Input value={this.state.material !== null ? this.state.material.category : ''}
                                    label={strings.table_material_category} placeholder={strings.table_material_category}
                                    onChange={this.handleChangeCategory} />
                                <Form.Input value={this.state.material !== null ? this.state.material.classification : ''}
                                    label={strings.table_material_classification} placeholder={strings.table_material_classification}
                                    onChange={this.handleChangeClassification} />
                                <Form.Input value={this.state.material !== null ? this.state.material.producer : ''}
                                    label={strings.table_material_producer} placeholder={strings.table_material_producer}
                                    onChange={this.handleChangeProducer} />
                                <Form.Input value={this.state.material !== null ? this.state.material.ce : ''}
                                    label={strings.table_material_ce} placeholder={strings.table_material_ce}
                                    onChange={this.handleChangeCe} />
                                <Form.Input value={this.state.material !== null ? this.state.material.norms : ''}
                                    label={strings.table_material_norms} placeholder={strings.table_material_norms}
                                    onChange={this.handleChangeNorms} />
                                <Form.Input value={this.state.material !== null ? this.state.material.batch_number : ''}
                                    label={strings.batch_number} placeholder={strings.batch_number}
                                    onChange={this.handleChangeBatchNumber} />
                                <Button icon="save" color='green' content={strings.save_prescription_button_label} onClick={() => this.saveButton()} />
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withCookies,
    withRouter
)(Material)