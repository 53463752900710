import React from 'react'
import { Grid, Icon, Label, Image, Segment, Button } from 'semantic-ui-react'
import { strings } from '../core/strings';
import TaskModal from './TaskModal';
import dateTimeFormatter from '../utils/dateTimeFormatter';
import TaskWorksModal from './TaskWorksModal';

const PlanningTasks = (props) => {

    
    let steps = props.tasks.map(p => {
        var ongoing = false;

        if (!p.completed && p.start_datetime != null) {
            ongoing = true;
        }

        return (
            <Segment secondary={ongoing} key={p.id_task}>
                <Grid columns={4}>
                    <Grid.Row verticalAlign='middle' textAlign='center'>
                        <Grid.Column width={2} >
                            {p.completed ? <Icon size='big' name='check' color='green' /> : null}
                            {ongoing ? <Label >{strings.ongoing}</Label> : null}
                        </Grid.Column>
                        <Grid.Column width={2} >
                        <b>{dateTimeFormatter(p.date_assignment)}</b>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Image spaced circular
                                style={{ width: "100px", height: "100px", objectFit: 'cover' }}
                                src={p.url_image}>
                            </Image>
                            <br />
                            <Label basic>{p.name + " " + p.surname}</Label>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <b>{p.inspector ? strings.inspection_label : p.type}</b>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <TaskWorksModal
                                task={p}
                                works={props.works}
                                open={props.modalOpen}
                                onChangeWork={props.onChangeWork}
                                onAddNewWork={props.onAddNewWork}
                                onChangeWorkQuantity={props.onChangeWorkQuantity}
                                onChangeWorkDiscount={props.onChangeWorkDiscount}
                                onChangeWorkPayment={props.onChangeWorkPayment}
                                current_works={props.current_works}
                                onDeleteWork={props.onDeleteWork}
                                onChangeCurrentTask={props.onChangeCurrentTask}
                                number={p.works.length}
                                onSave={props.onSaveTaskWorks}
                            />

                            <TaskModal
                                works={props.works}
                                operators={props.operators}
                                task={p}
                                onEdit={props.onEdit}
                                onChangeCurrentIdOperator={props.onChangeCurrentIdOperator}
                                onChangeCurrentCompleted={props.onChangeCurrentCompleted}
                                onChangeCurrentType={props.onChangeCurrentType}
                                onChangeCurrentTask={props.onChangeCurrentTask}
                                onChangeCurrentInspector={props.onChangeCurrentInspector}
                                onChangeCurrentDate={props.onChangeCurrentDate}
                                current_date={props.current_date}
                                open={props.modalOpen}
                            />

                            <Button onClick={() => props.onDelete(p.id_task)} icon color='red'><Icon name='trash alternate' /></Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    });

    return (
        <div>
            <Segment.Group raised>
                {steps}
            </Segment.Group>
        </div>
    );
}


export default (PlanningTasks);

