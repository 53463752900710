import React, { Component } from 'react';
import { Grid, Segment, Form, Button, Divider, Header } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import dateTimeFormatter from '../utils/dateTimeFormatter';


class Dentist extends Component {
    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            dentist: null,
            materials: [],
            loading: false
        }
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getDentist(this.props.dentistId);
    }


    getDentist = async (dentistId) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_dentist(this.state.accessToken, dentistId);
            if (response.data.data != null) {
                var dentist = response.data.data

                this.setState({
                    dentist: dentist,
                    loading: false
                });
            } else if(response.data.error.code == '23000') {
                const { cookies } = this.props;
                cookies.remove('USER_TOKEN');
                this.props.history.push("/");
            }else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }


    goBack = () => {
        this.props.history.goBack();
    }


    saveButton = () => {
        this.saveDentist();
    }


    saveDentist = async () => {

        var dentist = Object.assign({}, this.state.dentist);

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_dentist(this.state.accessToken, dentist);

            if (response.data.data != null) {

                this.props.history.goBack();

            } else {
                this.setState({
                    loading: false
                });
            }
            
        } catch (error) {
            // TODO handle error
            alert("KO");
            this.setState({
                loading: false
            });
        }
    }

    handleChangeName = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.name = value;
        this.setState({ dentist: dentist });

    }

    handleChangeSurname = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.surname = value;
        this.setState({ dentist: dentist });

    }

    handleChangeDescription = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.description = value;
        this.setState({ dentist: dentist });

    }
    handleChangeEmail = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.email = value;
        this.setState({ dentist: dentist });

    }


    handleChangeAddress = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.address = value;
        this.setState({ dentist: dentist });

    }

    handleChangePhone = (e, { value }) => {

        var dentist = Object.assign({}, this.state.dentist);
        dentist.phone_number = value;
        this.setState({ dentist: dentist });

    }


    cancelEditButton = () => {
        this.getDentist(this.state.dentist.id_dentist);
    }

    render() {

        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_ADMIN_DENTISTS} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>

                            <Grid columns={3}>
                                <Grid.Row >
                                    <Grid.Column textAlign='left' verticalAlign='middle' width={5}>
                                        <Button className='third-color-background white-color' content={strings.back} onClick={() => this.goBack()} />
                                    </Grid.Column>
                                    <Grid.Column textAlign='center' verticalAlign='middle' width={6}>
                                        <Header as='h2'> {strings.dentist_title} {this.state.dentist != null ? this.state.dentist.id_dentist : ''} </Header>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right' verticalAlign='middle' width={5}>
                                        <Button className='secondary-color-background white-color' content={strings.cancel_prescription_button_label} onClick={() => this.cancelEditButton()} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider />
                            <Form loading={this.state.loading} error={this.incomplete_submit} >
                                <Form.Input
                                    value={this.state.dentist !== null ? this.state.dentist.description : ''}
                                    label={strings.table_dentist_description} placeholder={strings.table_dentist_description}
                                    onChange={this.handleChangeDescription} />
                                <Form.Input
                                    value={this.state.dentist !== null ? this.state.dentist.name : ''}
                                    label={strings.table_dentist_name} placeholder={strings.table_dentist_name}
                                    onChange={this.handleChangeName} />
                                <Form.Input
                                    value={this.state.dentist !== null ? this.state.dentist.surname : ''}
                                    label={strings.table_dentist_surname} placeholder={strings.table_dentist_surname}
                                    onChange={this.handleChangeSurname} />
                                <Form.Input
                                    value={this.state.dentist !== null ? this.state.dentist.email : ''}
                                    label={strings.table_dentist_email} placeholder={strings.table_dentist_email}
                                    onChange={this.handleChangeEmail} />
                                <Form.Input
                                    value={this.state.dentist !== null ? this.state.dentist.address : ''}
                                    label={strings.table_dentist_address} placeholder={strings.table_dentist_address}
                                    onChange={this.handleChangeAddress} />
                                <Form.Input
                                    value={this.state.dentist !== null ? this.state.dentist.phone_number : ''}
                                    label={strings.table_dentist_phone} placeholder={strings.table_dentist_phone}
                                    onChange={this.handleChangePhone} />
                                <Button icon="save" color='green' content={strings.save_prescription_button_label} onClick={() => this.saveButton()} />
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withCookies,
    withRouter
)(Dentist)