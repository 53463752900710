import React, { Component } from 'react';
import { Grid, Segment, Form, Button, Divider, Header, Dropdown, Table, Icon } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import dateTimeFormatter from '../utils/dateTimeFormatter';
import NavigationHeader from './NavigationHeader';


class Work extends Component {
    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            work: null,
            works: [],
            loading: false,
            dentists: [],
            add: false,
            pers: {
                id_work: this.props.workId,
                id_dentist: null,
                code: null,
                price: null
            }
        }
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getWork(this.props.workId);
        this.getDentists();
    }


    getWork = async (workId) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_work(this.state.accessToken, workId);
            if (response.data.data != null) {
                var work = response.data.data

                this.setState({
                    work: work,
                    loading: false
                });
            } else if (response.data.error.code == '23000') {
                const { cookies } = this.props;
                cookies.remove('USER_TOKEN');
                this.props.history.push("/");
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    getDentists = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.get_dentists(this.state.accessToken);
            if (response.data.data != null) {
                const dentists = [...response.data.data].map(d => {
                    var obj = { key: d.id_dentist, text: d.name + " " + d.surname, value: d.id_dentist }
                    return obj
                })

                //const pagination = Object.assign({}, response.data.pagination)
                this.setState({
                    dentists: dentists,
                    loading: false
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }


    goBack = () => {
        this.props.history.goBack();
    }


    saveButton = () => {
        this.saveWork();
    }


    saveWork = async () => {

        var work = Object.assign({}, this.state.work);

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_work(this.state.accessToken, work);

            if (response.data.data != null) {

                this.props.history.goBack();

            } else {
                this.setState({
                    loading: false
                });
            }

        } catch (error) {
            // TODO handle error
            alert("KO");
            this.setState({
                loading: false
            });
        }
    }

    saveWorkPers = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_work_pers(this.state.accessToken, this.state.pers);

            if (response.data.data != null) {
                this.getWork(this.props.workId);
                this.setState({
                    pers: {
                        id_work: this.props.workId,
                        id_dentist: null,
                        code: null,
                        price: null
                    }
                })

            } else {
                this.setState({
                    loading: false
                });
            }

        } catch (error) {
            // TODO handle error
            alert("KO");
            this.setState({
                loading: false
            });
        }
    }

    revokeWorkPers = async (id_personalisation) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.revoke_work_pers(this.state.accessToken, id_personalisation);

            if (response.data.data != null) {
                this.getWork(this.props.workId);

            } else {
                this.setState({
                    loading: false
                });
            }

        } catch (error) {
            // TODO handle error
            alert("KO");
            this.setState({
                loading: false
            });
        }
    }

    handleChangeNameIt = (e, { value }) => {

        var work = Object.assign({}, this.state.work);
        work.name_it = value;
        this.setState({ work: work });

    }

    handleChangeNameEn = (e, { value }) => {

        var work = Object.assign({}, this.state.work);
        work.name_en = value;
        this.setState({ work: work });

    }

    handleChangeNameFr = (e, { value }) => {

        var work = Object.assign({}, this.state.work);
        work.name_fr = value;
        this.setState({ work: work });

    }


    handleChangeCode = (e, { value }) => {

        var work = Object.assign({}, this.state.work);
        work.code = value;
        this.setState({ work: work });

    }

    handleChangePrice = (e, { value }) => {

        var work = Object.assign({}, this.state.work);
        work.price = value;
        this.setState({ work: work });

    }

    handleChangeCodePers = (e, { value }) => {

        var pers = Object.assign({}, this.state.pers);
        pers.code = value;
        this.setState({ pers: pers });

    }

    handleChangePricePers = (e, { value }) => {

        var pers = Object.assign({}, this.state.pers);
        pers.price = value;
        this.setState({ pers: pers });

    }

    handleChangeDentist = (e, { value }) => {

        var pers = Object.assign({}, this.state.pers);
        pers.id_dentist = value;
        this.setState({ pers: pers });

    }

    cancelEditButton = () => {
        this.getWork(this.state.work.id_work);
    }

    handleAddPersonalisationClick = () => {
        this.setState({
            add: !this.state.add
        });
    }

    onCancelPersonalisation = (id_personalisation) => {
        this.revokeWorkPers(id_personalisation);
    }

    render() {


        let rows = this.state.work != null ? this.state.work.personalisations.map(p => {
            var index = this.state.dentists.map(function (o) { return o.key; }).indexOf(p.id_dentist);

            return (
                <Table.Row textAlign='center' key={p.id_personalisation}>
                    <Table.Cell >{this.state.dentists[index] != null ? this.state.dentists[index].text : ''}</Table.Cell>
                    <Table.Cell >{p.code}</Table.Cell>
                    <Table.Cell >{p.price}</Table.Cell>
                    <Table.Cell >
                        <Button color='red'
                            onClick={() => this.onCancelPersonalisation(p.id_personalisation)} >
                            <Icon name='edit outline' />
                            {strings.cancel_prescription_button_label}
                        </Button>
                    </Table.Cell>
                </Table.Row>
            )
        }) : [];

        let add_work_personalisation = this.state.add ? <Form loading={this.state.loading} error={this.incomplete_submit} >
            <Form.Dropdown onChange={this.handleChangeDentist} label={strings.table_dentist_name} placeholder={strings.table_dentist_name} search selection options={this.state.dentists} />
            <Form.Input value={this.state.work !== null && this.state.pers !== null? this.state.pers.code : ''}
                label={strings.code} placeholder={strings.code}
                onChange={this.handleChangeCodePers} />
            <Form.Input value={this.state.work !== null && this.state.pers !== null? this.state.pers.price : ''}
                label={strings.price} placeholder={strings.price}
                onChange={this.handleChangePricePers} />
            <Button icon="save" color='green' content={strings.save_prescription_button_label} onClick={() => this.saveWorkPers()} />
        </Form> : null;

        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_WORKSLIST} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>

                            <Grid columns={3}>
                                <Grid.Row >
                                    <Grid.Column textAlign='left' verticalAlign='middle' width={5}>
                                        <Button className='third-color-background white-color' content={strings.back} onClick={() => this.goBack()} />
                                    </Grid.Column>
                                    <Grid.Column textAlign='center' verticalAlign='middle' width={6}>
                                        <Header as='h2'> {strings.work}</Header>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right' verticalAlign='middle' width={5}>
                                        <Button className='secondary-color-background white-color' content={strings.cancel_prescription_button_label} onClick={() => this.cancelEditButton()} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider />
                            <Form loading={this.state.loading} error={this.incomplete_submit} >
                                <Form.Input value={this.state.work !== null ? this.state.work.name_it : ''}
                                    label={strings.table_material_name_it} placeholder={strings.table_material_name_it}
                                    onChange={this.handleChangeNameIt} />
                                <Form.Input value={this.state.work !== null ? this.state.work.name_en : ''}
                                    label={strings.table_material_name_en} placeholder={strings.table_material_name_en}
                                    onChange={this.handleChangeNameEn} />
                                <Form.Input value={this.state.work !== null ? this.state.work.name_fr : ''}
                                    label={strings.table_material_name_fr} placeholder={strings.table_material_name_fr}
                                    onChange={this.handleChangeNameFr} />
                                <Form.Group widths='equal' >
                                    <Form.Input value={this.state.work !== null ? this.state.work.code : ''}
                                        label={strings.code} placeholder={strings.code}
                                        onChange={this.handleChangeCode} />
                                    <Form.Input value={this.state.work !== null ? this.state.work.price : ''}
                                        label={strings.price} placeholder={strings.price}
                                        onChange={this.handleChangePrice} />
                                </Form.Group>

                                <Button icon="save" color='green' content={strings.save_prescription_button_label} onClick={() => this.saveButton()} />
                            </Form>
                        </Segment>

                        <Segment raised>
                            <NavigationHeader title={strings.work_personalisation} action_button={true}
                                action_button_content={this.state.add ? strings.add_dentist_close_button : strings.add_work_personalisation}
                                action_button_handleClick={() => this.handleAddPersonalisationClick()}
                            >
                            </NavigationHeader>

                            {add_work_personalisation}
                            <Table sortable celled structured>
                                <Table.Header>
                                    <Table.Row textAlign='center'>
                                        <Table.HeaderCell rowSpan='1'>{strings.table_dentist_name}</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan='1'>{strings.code}</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan='1'>{strings.price}</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan='1'></Table.HeaderCell>
                                    </Table.Row>

                                </Table.Header>
                                <Table.Body>
                                    {rows}
                                </Table.Body>
                                <Table.Footer>
                                </Table.Footer>
                            </Table>

                        </Segment>

                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withCookies,
    withRouter
)(Work)