import React, { Component } from 'react';
import { Grid, Segment, Button, Loader, Form } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import WorkslistTable from './WorkslistTable';

class WorkslistRegistry extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            works: [],
            add: false,
            work: {}
        };
    }


    componentDidMount() {
        this.getWorksList();
    }

    addWork = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_work(this.state.accessToken, this.state.work);
            if (response.data.data != null) {

                this.setState({
                    loading: false,
                    add: false
                });

                this.getWorksList();

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    getWorksList= async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.get_workslist(this.state.accessToken);
            if (response.data.data != null) {
                const works = [...response.data.data]
                //const pagination = Object.assign({}, response.data.pagination)
                this.setState({
                    works: works,
                    loading: false
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    onEditWork = async (id) => {
        this.routeChange(config.MENU_ITEM_WORKSLIST + "/" + id)
    }

    routeChange = (path) => {
        this.props.history.push(path);
    }

    handleAddWorkClick = () => {
        this.setState({
            add: !this.state.add
        });
    }

    render() {

        const add_material = this.state.add ? <Segment>
            <Form >
                <Form.Input required
                    label={strings.table_material_name_it} placeholder={strings.table_material_name_it}
                    onChange={this.handleChangeNameIt} />
                <Form.Input required
                    label={strings.table_material_name_en} placeholder={strings.table_material_name_en}
                    onChange={this.handleChangeNameEn} />
                <Form.Input required
                    label={strings.table_material_name_fr} placeholder={strings.table_material_name_fr}
                    onChange={this.handleChangeNameFr} />
                <Form.Input required
                    label={strings.code} placeholder={strings.code}
                    onChange={this.handleChangeCode} />
                <Form.Input 
                    label={strings.price} placeholder={strings.price}
                    onChange={this.handleChangePrice} />
                <Button icon="save" color='green' content={strings.save_dentist_button} onClick={() => this.addWork()} />
            </Form>

        </Segment> : null

        return (
            <div>

                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_WORKSLIST} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>
                            <NavigationHeader title={strings.workslist} action_button={true}
                                action_button_content={this.state.add ? strings.add_dentist_close_button : strings.add_work}
                                action_button_handleClick={() => this.handleAddWorkClick()}
                            >
                            </NavigationHeader>
                            <Loader className='custom-loader' active={this.state.loading} disabled={!this.state.loading} />
                            {add_material}
                            <WorkslistTable
                                works={this.state.works}
                                onEditWork={(id) => this.onEditWork(id)}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>

            </div>
        );
    }

    handleChangeNameIt = (e, { value }) => {

        var work = Object.assign({}, this.state.work);
        work.name_it = value;
        this.setState({ work: work });

    }

    handleChangeNameEn = (e, { value }) => {

        var work = Object.assign({}, this.state.work);
        work.name_en = value;
        this.setState({ work: work });

    }

    handleChangeNameFr = (e, { value }) => {

        var work = Object.assign({}, this.state.work);
        work.name_fr = value;
        this.setState({ work: work });

    }


    handleChangeCode = (e, { value }) => {

        var work = Object.assign({}, this.state.work);
        work.code = value;
        this.setState({ work: work });

    }

    handleChangePrice = (e, { value }) => {

        var work = Object.assign({}, this.state.work);
        work.price = value;
        this.setState({ work: work });

    }

}

export default compose(
    withCookies,
    withRouter
)(WorkslistRegistry)