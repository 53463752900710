import React, { Component } from 'react';
import { Grid, Segment, Form, Button, Divider, Header } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';

const roleOptions =[{
    key: 'admin',
    text: strings.role_admin,
    value: 'admin',
  },{
    key: 'secretary',
    text: strings.role_secretary,
    value: 'secretary',
  }]

class UserReg extends Component {
    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            user: null,
            loading: false
        }
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getUser(this.props.userId);
    }


    getUser = async (userId) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_user(this.state.accessToken, userId);
            if (response.data.data != null) {
                var user = response.data.data

                this.setState({
                    user: user,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }


    goBack = () => {
        this.props.history.goBack();
    }


    saveButton = () => {
        this.saveUser();
    }


    saveUser = async () => {

        var user = Object.assign({}, this.state.user);

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_user(this.state.accessToken, user);

            if (response.data.data != null) {

                this.props.history.goBack();

            } else {
                this.setState({
                    loading: false
                });
            }

        } catch (error) {
            // TODO handle error
            alert("KO");
            this.setState({
                loading: false
            });
        }
    }

    handleChangeName = (e, { value }) => {

        var user = Object.assign({}, this.state.user);
        user.name = value;
        this.setState({ user: user });

    }

    handleChangeSurname = (e, { value }) => {

        var user = Object.assign({}, this.state.user);
        user.surname = value;
        this.setState({ user: user });

    }

    handleChangeEmail = (e, { value }) => {

        var user = Object.assign({}, this.state.user);
        user.email = value;
        this.setState({ user: user });

    }
    handleChangeRole = (e, { value }) => {

        var user = Object.assign({}, this.state.user);
        user.role = value;
        this.setState({ user: user });

    }



    cancelEditButton = () => {
        this.getUser(this.state.user.id_user);
    }

    render() {

        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_ADMIN_USERS} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>

                            <Grid columns={3}>
                                <Grid.Row >
                                    <Grid.Column textAlign='left' verticalAlign='middle' width={5}>
                                        <Button className='third-color-background white-color' content={strings.back} onClick={() => this.goBack()} />
                                    </Grid.Column>
                                    <Grid.Column textAlign='center' verticalAlign='middle' width={6}>
                                        <Header as='h2'> {strings.user_title} {this.state.user != null ? this.state.user.id_user : ''} </Header>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right' verticalAlign='middle' width={5}>
                                        <Button className='secondary-color-background white-color' content={strings.cancel_prescription_button_label} onClick={() => this.cancelEditButton()} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider />
                            <Form loading={this.state.loading} error={this.incomplete_submit} >
                                <Form.Input
                                    value={this.state.user !== null ? this.state.user.email : ''}
                                    label={strings.table_user_email} placeholder={strings.table_user_email}
                                    onChange={this.handleChangeEmail} />
                                <Form.Input
                                    value={this.state.user !== null ? this.state.user.name : ''}
                                    label={strings.table_user_name} placeholder={strings.table_user_name}
                                    onChange={this.handleChangeName} />
                                <Form.Input
                                    value={this.state.user !== null ? this.state.user.surname : ''}
                                    label={strings.table_user_surname} placeholder={strings.table_user_surname}
                                    onChange={this.handleChangeSurname} />
                                <Form.Dropdown
                                    value={this.state.user !== null ? this.state.user.role : ''}
                                    label={strings.table_user_role} placeholder={strings.table_user_role} selection options={roleOptions}
                                    onChange={this.handleChangeRole} />
                                <Button icon="save" color='green' content={strings.save_prescription_button_label} onClick={() => this.saveButton()} />
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withCookies,
    withRouter
)(UserReg)