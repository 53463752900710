
import * as actionTypes from '../actions';

const initialState = {
    prescriptions_orderby: [],
    prescriptions_active: {
        date_requested: null,
        status: null
    },
    prescriptions_filters: [],
    page: null
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PRESCRIPTIONS_TABLE_DATA:
            return {
                ...state,
                prescriptions_orderby: action.orderby,
                prescriptions_active: Object.assign({}, action.active),
                prescriptions_filters: Object.assign({}, action.filters),
                page: action.page
            }
        default: {
            return state;
        }
    }
};

export default dataReducer;