import React, { Component } from 'react';
import { Grid, Segment, Button, Loader, Form } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import MaterialsTable from './MaterialsTable';

class MaterialsRegistry extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            materials: [],
            add: false,
            material: {}
        };
    }


    componentDidMount() {
        this.getMaterialsCertification();
    }

    addMaterial = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_material_certification(this.state.accessToken, this.state.material);
            if (response.data.data != null) {

                this.setState({
                    loading: false,
                    add: false
                });

                this.getMaterialsCertification();

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    getMaterialsCertification = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.get_materials_certification(this.state.accessToken);
            if (response.data.data != null) {
                const materials = [...response.data.data]
                //const pagination = Object.assign({}, response.data.pagination)
                this.setState({
                    materials: materials,
                    loading: false
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    onEditMaterial = async (id) => {
        this.routeChange(config.MENU_ITEM_ADMIN_MATERIALS + "/" + id)
    }

    routeChange = (path) => {
        this.props.history.push(path);
    }

    handleAddMaterialClick = () => {
        this.setState({
            add: !this.state.add
        });
    }

    render() {

        const add_material = this.state.add ? <Segment>
            <Form >
                <Form.Input required
                    label={strings.table_material_name_it} placeholder={strings.table_material_name_it}
                    onChange={this.handleChangeNameIt} />
                <Form.Input required
                    label={strings.table_material_name_en} placeholder={strings.table_material_name_en}
                    onChange={this.handleChangeNameEn} />
                <Form.Input required
                    label={strings.table_material_name_fr} placeholder={strings.table_material_name_fr}
                    onChange={this.handleChangeNameFr} />
                <Form.Input 
                    label={strings.table_material_type} placeholder={strings.table_material_type}
                    onChange={this.handleChangeType} />
                <Form.Input 
                    label={strings.table_material_category} placeholder={strings.table_material_category}
                    onChange={this.handleChangeCategory} />
                <Form.Input 
                    label={strings.table_material_classification} placeholder={strings.table_material_classification}
                    onChange={this.handleChangeClassification} />
                <Form.Input 
                    label={strings.table_material_producer} placeholder={strings.table_material_producer}
                    onChange={this.handleChangeProducer} />
                <Form.Input 
                    label={strings.table_material_ce} placeholder={strings.table_material_ce}
                    onChange={this.handleChangeCe} />
                <Form.Input 
                    label={strings.table_material_norms} placeholder={strings.table_material_norms}
                    onChange={this.handleChangeNorms} />
                <Button icon="save" color='green' content={strings.save_dentist_button} onClick={() => this.addMaterial()} />
            </Form>

        </Segment> : null

        return (
            <div>

                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_ADMIN_MATERIALS} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>
                            <NavigationHeader title={strings.materials_label} action_button={true}
                                action_button_content={this.state.add ? strings.add_dentist_close_button : strings.add_material_button}
                                action_button_handleClick={() => this.handleAddMaterialClick()}
                            >
                            </NavigationHeader>
                            <Loader className='custom-loader' active={this.state.loading} disabled={!this.state.loading} />
                            {add_material}
                            <MaterialsTable
                                materials={this.state.materials}
                                onEditMaterial={(id) => this.onEditMaterial(id)}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>

            </div>
        );
    }

    handleChangeNameIt = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.name_it = value;
        this.setState({ material: material });

    }

    handleChangeNameEn = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.name_en = value;
        this.setState({ material: material });

    }

    handleChangeNameFr = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.name_fr = value;
        this.setState({ material: material });

    }


    handleChangeType = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.type = value;
        this.setState({ material: material });

    }

    handleChangeCategory = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.category = value;
        this.setState({ material: material });

    }
    handleChangeClassification = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.classification = value;
        this.setState({ material: material });

    }


    handleChangeProducer = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.producer = value;
        this.setState({ material: material });

    }

    handleChangeCe = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.ce = value;
        this.setState({ material: material });

    }

    handleChangeNorms = (e, { value }) => {

        var material = Object.assign({}, this.state.material);
        material.norms = value;
        this.setState({ material: material });

    }

}

export default compose(
    withCookies,
    withRouter
)(MaterialsRegistry)