import React from 'react'
import { Button, Modal, Form, Segment, Icon } from 'semantic-ui-react'
import { strings } from '../core/strings';
import {
    DateInput
} from 'semantic-ui-calendar-react-17';

function TaskModal(props) {
    const [open, setOpen] = React.useState(false)

    const onClick = () => {
        props.onEdit();
        setOpen(false);
    }

    return (
        <Modal size='large'
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button onClick={() => props.onChangeCurrentTask(props.task)} className='third-color-background white-color'>{props.action_button_content != undefined ? props.action_button_content : strings.edit_text_button}</Button>}
        >
            <Modal.Header>{props.task.id_operator != 0 ? strings.edit_task_title : strings.new_task_title}</Modal.Header>

            <Modal.Content >
                <Form size='huge'>
                        <Form.Field>
                            <label> {strings.table_prescription_date_assigment} </label>
                            <DateInput
                                localization='it'
                                name="date"
                                value={(props.current_date)}
                                iconPosition="left"
                                onChange={props.onChangeCurrentDate}
                            />
                        </Form.Field>
                        <Form.Group>
                            <Form.Checkbox
                                defaultChecked={props.task.inspector}
                                label='Inspector'
                                onChange={props.onChangeCurrentInspector}>
                            </Form.Checkbox>
                            <Form.Checkbox disabled
                                defaultChecked={props.task.completed}
                                label='Completato'
                                onChange={props.onChangeCurrentCompleted}>
                            </Form.Checkbox>
                        </Form.Group>
                        <Form.Dropdown required
                            label='Seleziona operatore'
                            placeholder='Seleziona operatore'
                            defaultValue={props.task.id_operator}
                            selection
                            options={props.operators}
                            onChange={props.onChangeCurrentIdOperator}
                        />
                        <Form.TextArea disabled={props.task.inspector}
                            defaultValue={props.task.inspector ? strings.inspection_label : props.task.type}
                            label='Attività'
                            onChange={props.onChangeCurrentType}>
                        </Form.TextArea>

                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button className='secondary-color-background' onClick={() => setOpen(false)}>
                    {strings.message_action_giveup}
                </Button>
                <Button
                    content={strings.save_user_button}
                    labelPosition='right'
                    icon='save'
                    onClick={() => onClick()}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default TaskModal
