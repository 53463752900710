import React from 'react'
import { Button, Modal, Grid, Input, Icon, Divider, Dropdown } from 'semantic-ui-react'
import { strings } from '../core/strings';

function TaskMaterialModal(props) {
    const [open, setOpen] = React.useState(false)

    const onClick = (id_task) => {
        props.onSave(id_task);
        setOpen(false);
    }

    const onClose = () => {
        setOpen(false);
    }

    //var todo = false;

    const materials = props.current_materials.map(m => {

        if (m.batch_number == '' || m.batch_number == null) {
            //todo = true;
        }
        return <Grid.Row key={props.id_task + "-" + m.id_material}>
            <Grid.Column width={4} verticalAlign='middle'>
                <b>{m.name_it}</b>
            </Grid.Column>
            <Grid.Column width={4} verticalAlign='middle'>
                <Input label={strings.quantity_abb} onChange={(e, value) => props.handleChangQuantity(props.id_task, m.id_material, e, value)}
                    value={m.quantity} defaultValue={0} fluid />
            </Grid.Column>
            <Grid.Column width={6} verticalAlign='middle'>
                <Input label={strings.batch_number_abb} onChange={(e, value) => props.handleChange(props.id_task, m.id_material, e, value)}
                   value={m.batch_number} defaultValue="" fluid />
            </Grid.Column>
            <Grid.Column width={2} verticalAlign='middle'>
                <Button icon
                    width={2} onClick={() => props.onDeleteMaterial(m)} color='red'>
                    <Icon name='trash alternate' />
                </Button>
            </Grid.Column>
        </Grid.Row>

    })

    return (
        <Modal size='tiny'
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button onClick={() => props.onChangeCurrentTask(props.task)} className='third-color-background white-color'>{strings.batch_number}</Button>}
        >
            <Modal.Header>{strings.batch_number}</Modal.Header>

            <Modal.Content >
                <Grid columns={4}>
                    {materials}
                </Grid>
                <Divider></Divider>
                <h5>{strings.add_material_button}</h5>
                <Dropdown width={6} search
                    label={strings.add_material_button}
                    placeholder={strings.add_material_button}
                    selection
                    options={props.all_materials}
                    onChange={props.onAddNewMaterial}
                />

            </Modal.Content>
            <Modal.Actions>
                <Button className='secondary-color-background' onClick={() => onClose()}>
                    {strings.cancel_prescription_button_label}
                </Button>
                <Button
                    content={strings.save_user_button}
                    labelPosition='right'
                    icon='save'
                    onClick={() => onClick(props.id_task)}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default TaskMaterialModal
