import React, { Component } from 'react';
import { Grid, Segment, Form, Button, Image, Divider, Header } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';

class Operator extends Component {
    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            operator: null,
            loading: false
        }
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getOperator(this.props.operatorId);
    }


    getOperator = async (operatorId) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_operator(this.state.accessToken, operatorId);
            if (response.data.data != null) {
                var operator = response.data.data

                this.setState({
                    operator: operator,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }


    goBack = () => {
        this.props.history.goBack();
    }


    saveButton = () => {
        this.saveOperator();
    }


    saveOperator = async () => {

        var operator = Object.assign({}, this.state.operator);

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_operator(this.state.accessToken, operator);

            if (response.data.data != null) {

                this.props.history.goBack();

            } else {
                this.setState({
                    loading: false
                });
            }

        } catch (error) {
            // TODO handle error
            alert("KO");
            this.setState({
                loading: false
            });
        }
    }

    handleChangeName = (e, { value }) => {

        var operator = Object.assign({}, this.state.operator);
        operator.name = value;
        this.setState({ operator: operator });

    }

    handleChangeSurname = (e, { value }) => {

        var operator = Object.assign({}, this.state.operator);
        operator.surname = value;
        this.setState({ operator: operator });

    }

    handleChangeLab = (e, { value }) => {

        var operator = Object.assign({}, this.state.operator);
        operator.lab = value;
        this.setState({ operator: operator });

    }

    handleChangeInspector = (e, { checked }) => {

        var operator = Object.assign({}, this.state.operator);
        operator.inspector = checked;
        this.setState({ operator: operator });

    }

    handleChangeHidden = (e, { checked }) => {

        var operator = Object.assign({}, this.state.operator);
        operator.hidden = checked;
        this.setState({ operator: operator });

    }

    handleInputFile(e) {
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        var operator = Object.assign({}, this.state.operator);

        reader.onload = (e) => {
            operator.new_image = (e.target.result);

            this.setState({
                operator: operator
            })
        }
    }



    cancelEditButton = () => {
        this.getOperator(this.state.operator.id_operator);
    }

    render() {

        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_ADMIN_OPERATORS} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>

                            <Grid columns={3}>
                                <Grid.Row >
                                    <Grid.Column textAlign='left' verticalAlign='middle' width={5}>
                                        <Button className='third-color-background white-color' content={strings.back} onClick={() => this.goBack()} />
                                    </Grid.Column>
                                    <Grid.Column textAlign='center' verticalAlign='middle' width={6}>
                                        <Header as='h2'> {strings.operator_title} {this.state.operator != null ? this.state.operator.id_operator : ''} </Header>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right' verticalAlign='middle' width={5}>
                                        <Button className='secondary-color-background white-color' content={strings.cancel_prescription_button_label} onClick={() => this.cancelEditButton()} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider />
                            <Form loading={this.state.loading} error={this.incomplete_submit} >

                                <Form.Checkbox
                                    checked={this.state.operator !== null ? this.state.operator.inspector : ''}
                                    label={strings.table_operator_inspector} onChange={this.handleChangeInspector} />
                                <Form.Input required
                                    value={this.state.operator !== null ? this.state.operator.name : ''}
                                    label={strings.table_operator_name} placeholder={strings.table_operator_name}
                                    onChange={this.handleChangeName} />

                                <Form.Input required
                                    value={this.state.operator !== null ? this.state.operator.surname : ''}
                                    label={strings.table_operator_surname} placeholder={strings.table_operator_surname}
                                    onChange={this.handleChangeSurname} />

                                <Form.Input required
                                    value={this.state.operator !== null ? this.state.operator.lab : ''}
                                    label={strings.table_operator_lab} placeholder={strings.table_operator_lab}
                                    onChange={this.handleChangeLab} />

                                <Form.Checkbox
                                    checked={this.state.operator !== null ? this.state.operator.hidden : ''}
                                    label={strings.table_operator_hidden} onChange={this.handleChangeHidden} />
                                <Button
                                    content={strings.upload_new_image_label}
                                    labelPosition="left"
                                    icon="file"
                                    onClick={() => this.fileInputRef.current.click()}
                                />
                                <input
                                    ref={this.fileInputRef}
                                    type="file"
                                    hidden
                                    onChange={(e) => this.handleInputFile(e)}
                                />
                                <p></p>
                                <Image size='small' src={this.state.operator !== null ? this.state.operator.new_image != undefined ? this.state.operator.new_image : this.state.operator.url_image : ''}></Image>
                                <p></p>
                                <Button icon="save" color='green' content={strings.save_prescription_button_label} onClick={() => this.saveButton()} />
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withCookies,
    withRouter
)(Operator)