import React, { Component } from 'react';
import { Grid, Segment, Form, Dropdown, Image, Header, Menu, Icon, Input, Loader } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import dateTimeFormatter from '../utils/dateTimeFormatter';
import OperatorsProductivityTable from './OperatorsProcuctivityTable.js';

const paymentOptions = [
    { key: 'paid', text: strings.paid, value: true },
    { key: 'free', text: strings.free, value: false },
]

class Dasboard extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            loading: false,
            data: [],
            operators: [],
            id_operator: null
        };

    }

    componentDidMount() {
        this.getOperators();
    }

    render() {

        return (
            <div>

                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_DASHBOARD} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>
                            <NavigationHeader title={strings.dashboard}></NavigationHeader>
                            <Loader active={this.state.loading} disabled={!this.state.loading} />
                            <Form.Dropdown required
                                placeholder='Seleziona operatore'
                                defaultValue={this.state.id_operator}
                                selection
                                options={this.state.operators}
                                onChange={this.handleChangeOperator}
                            />
                            <br></br>
                            <OperatorsProductivityTable data={this.state.data} changePage={this.changePage} pagination={this.state.pagination}/>
                        </Segment>
                    </Grid.Column>
                </Grid>


            </div>
        );
    }

    changePage = async (page) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_page(page);
            const data = [...response.data.data];
            const pagination = response.data.pagination;

            this.setState({
                data: data,
                pagination: pagination,
                loading: false,
                page: page
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    handleChangeOperator = (e, { value }) => {
        this.getData(value)
        this.setState({ id_operator: value });
    }

    getOperators = async () => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_operators(this.state.accessToken);
            if (response.data.data != null) {
                const operators = [...response.data.data].map(o => {
                    o.key = o.id_operator;
                    o.text = o.name + " " + o.surname;
                    o.value = o.id_operator;
                    o.image = { avatar: true, src: o.url_image, style: { width: "30px", height: "30px", objectFit: 'cover' } };
                    return (o)
                })

                //const pagination = Object.assign({}, response.data.pagination)
                this.setState({
                    operators: operators,
                    loading: false
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    getData = async (id_operator) => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_data(this.state.accessToken, id_operator);
            if (response.data.data != null) {
                const data = [...response.data.data]
                const pagination = Object.assign({}, response.data.pagination)

                console.log(data)
                this.setState({
                    data: data,
                    current_materials: [],
                    pagination: pagination,
                    loading: false
                });

            } else if (response.data.error.code == '23000') {
                const { cookies } = this.props;
                cookies.remove('USER_TOKEN');
                this.props.history.push("/");
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withCookies,
    withRouter
)(Dasboard)