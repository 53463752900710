import React, { Component } from 'react';
import { Grid, Segment, Label, Form, Image, Header, Menu, Icon, Input, Loader, Button, Divider, GridColumn } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import dateTimeFormatter from '../utils/dateTimeFormatter';
import TaskMaterialModal from './TaskMaterialModal';
import TaskWorksModal from './TaskWorksModal';
import AdditionalCostModal from './AdditionalCostModal';

const paymentOptions = [
    { key: 'paid', text: strings.paid, value: true },
    { key: 'free', text: strings.free, value: false },
]

class Accounting extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            prescriptions: [],
            pagination: "",
            loading: false,
            payment: 'free',
            cod_prescription: '',
            workslist: [],
            modalOpen: null,
            current_works: [],
            current_materials: [],
            materials: [],
            new_additional_cost_description: '',
            new_additional_cost_price: 0
        };
    }

    routeChange = (path) => {
        this.props.history.push(path);
    }

    componentDidMount() {
        this.getPrescriptions();
        this.getWorkslist();
        this.getMaterialsCertification();
    }

    getMaterialsCertification = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.get_materials_certification(this.state.accessToken);
            if (response.data.data != null) {
                const materials = [...response.data.data].map(t => {
                    return { text: t.name_it, key: t.id_material, value: t.id_material }
                }
                )
                //const pagination = Object.assign({}, response.data.pagination)
                console.log(materials);
                this.setState({
                    materials: materials,
                    loading: false
                });

            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    getWorkslist = async () => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_workslist(this.state.accessToken, true);
            if (response.data.data != null) {
                const workslist = [...response.data.data]

                this.setState({
                    workslist: workslist,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    getPrescriptions = async (cod_prescription) => {

        var searchFilters = [];
        var orderBy = ['order_by_date_requested_desc'];
        if (cod_prescription != null) {
            searchFilters['id_prescription'] = cod_prescription;
        } else {
            searchFilters['id_prescription'] = this.state.cod_prescription;
        }

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_prescriptions(this.state.accessToken, orderBy, searchFilters, 10);
            if (response.data.data != null) {
                const prescriptions = [...response.data.data]
                const pagination = Object.assign({}, response.data.pagination)

                this.setState({
                    prescriptions: prescriptions,
                    current_materials: [],
                    pagination: pagination,
                    loading: false
                });

            } else if (response.data.error.code == '23000') {
                const { cookies } = this.props;
                cookies.remove('USER_TOKEN');
                this.props.history.push("/");
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    handleChangePayment = (id_prescription, id_task, e, { value }) => {

        const prescriptions = this.state.prescriptions.map(p => {

            if (p.id_prescription == id_prescription) {
                p.tasks = p.tasks.map(t => {
                    if (t.id_task == id_task) {
                        t.payment = value;
                    }
                    return t;
                })
            }
            return p;

        }
        )

        this.setState({
            prescriptions: prescriptions
        });

        this.updateTaskPayment(id_task, value);
    }

    confirmPayment = async (id_prescription) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_prescription_payment(this.state.accessToken, id_prescription);

            this.setState({
                loading: false
            });

            this.getPrescriptions();


        } catch (error) {
            // TODO handle error

            this.setState({
                loading: false
            });
            this.getPrescriptions();
        }
    }


    updateTaskPayment = async (id_task, payment) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_task_payment(this.state.accessToken, id_task, payment);

            this.setState({
                loading: false
            });

            if (response.data.data == null || response.data.data === false) {
                this.getPrescriptions();
            }

        } catch (error) {
            // TODO handle error

            this.setState({
                loading: false
            });
            this.getPrescriptions();
        }
    }

    handleChangeInvoiceDescription = (id_task, e, { value }) => {

        //this.saveInvoiceDescription(id_task, value);
    }
    handleChangeBatchNumber = (id_task, id_material, e, { value }) => {

        var current_materials = [...this.state.current_materials];

        const index = current_materials.findIndex(element => element.id_material == id_material);


        if (index !== -1) {
            current_materials[index].batch_number = value;
        }

        this.setState({
            current_materials: current_materials,
        });
    }

    handleChangeMaterialQuantity = (id_task, id_material, e, { value }) => {

        if (value.match(/^\d+\.?\d*$/) || value == "") {

            var current_materials = [...this.state.current_materials];

            const index = current_materials.findIndex(element => element.id_material == id_material);

            if (index !== -1) {
                current_materials[index].quantity = value;

            }

            this.setState({
                current_materials: current_materials,
            });
        }
    }

    handleChangeWorkCode = (id_task, e, { value }) => {

        this.saveTaskWorkcode(id_task, value);
    }

    handleChangeDiscount = (id_task, e, { value }) => {

        this.saveTaskDiscount(id_task, value);
    }

    handleChangeNewAdditionalCostDescription = (e, { value }) => {

        this.setState({
            new_additional_cost_description: value
        })
    }

    handleChangeNewAdditionalCostPrice = (e, { value }) => {

        this.setState({
            new_additional_cost_price: value
        })
    }

    saveTaskDiscount = async (id_task, discount) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_task_discount(this.state.accessToken, id_task, discount);

            this.setState({
                loading: false
            });


        } catch (error) {
            // TODO handle error
            this.setState({
                loading: false
            });
        }
    }

    saveTaskWorkcode = async (id_task, work_code) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_task_workcode(this.state.accessToken, id_task, work_code);

            this.setState({
                loading: false
            });

            if (this.state.cod_prescription != '') {
                this.getPrescriptions(this.state.cod_prescription)
            } else {
                this.getPrescriptions();
            }
        } catch (error) {
            // TODO handle error
            this.setState({
                loading: false
            });
        }
    }

    saveInvoiceDescription = async (id_task, description) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_task_invoice_description(this.state.accessToken, id_task, description);

            this.setState({
                loading: false
            });

        } catch (error) {
            // TODO handle error
            this.setState({
                loading: false
            });
        }
    }

    saveBatchNumber = async (id_task, id_material, value) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_task_material_batch_number(this.state.accessToken, id_task, id_material, value);

            this.setState({
                loading: false
            });

        } catch (error) {
            
            // TODO handle error
            this.setState({
                loading: false
            });
        }
    }

    handleChangePrescriptionCode = (e, { value }) => {
        this.setState({ cod_prescription: value });
        this.getPrescriptions(value);
    }

    changePage = async (page) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_page(page);
            const prescriptions = [...response.data.data];
            const pagination = response.data.pagination;

            this.setState({
                prescriptions: prescriptions,
                pagination: pagination,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    handleChangeCurrentWork = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            id_work: data.value,
            quantity: data.quantity,
            discount: data.discount,
            payment: data.payment
        }
        const result = current_works.findIndex(element => element.id_work == el.id_work);

        current_works[result] = el


        this.setState({
            current_works: current_works,
        });
    }

    handleAddNewCurrentWork = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            id_work: data.value,
            quantity: 1,
            discount: 0,
            payment: 1
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);
        if (result === -1) {
            current_works.push(el)

        }

        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentWorkQuantity = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            quantity: data.value,
            id_work: data.id_work,
            discount: data.discount,
            payment: data.payment
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);

        current_works[result] = el


        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentWorkPayment = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            quantity: data.quantity,
            id_work: data.id_work,
            discount: data.discount,
            payment: data.value
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);

        current_works[result] = el


        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentWorkDiscount = (e, data) => {
        var current_works = [...this.state.current_works];

        var el = {
            quantity: data.quantity,
            id_work: data.id_work,
            discount: data.value,
            payment: data.payment
        }

        const result = current_works.findIndex(element => element.id_work == el.id_work);
        if (result === -1) {
            current_works.push(el)

        } else {
            current_works[result] = el
        }

        this.setState({
            current_works: current_works,
        });
    }

    handleChangeCurrentTask = (task) => {

        this.setState({
            current_task_id: task.id_task,
            current_works: task.works,
            current_materials: task.materials_certification
        });
    }

    handleDeleteCurrentWork = (w) => {

        var current_works = [...this.state.current_works];

        const result = current_works.findIndex(element => element.id_work == w.id_work);
        if (result === -1) {
        } else {
            current_works.splice(result, 1);

        }
        this.setState({ current_works: current_works });

    }

    handleDeleteCurrentMaterial = (m) => {

        var current_materials = [...this.state.current_materials];

        const result = current_materials.findIndex(element => element.id_material == m.id_material);
        if (result === -1) {
        } else {
            current_materials.splice(result, 1);

        }
        this.setState({ current_materials: current_materials });

    }

    handleSaveTaskWorks = async (id_task) => {
        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_task_works(
                this.state.accessToken,
                id_task,
                this.state.current_works
            );
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                    new_modalOpen: false
                });
                this.getPrescriptions();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }

    }

    handleSaveTaskMaterials = async (id_task) => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_task_materials(
                this.state.accessToken,
                id_task,
                this.state.current_materials
            );
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                    modalOpen: false
                });
                this.getPrescriptions();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }

    }

    handleAddAdditionalCost = async (id_prescription) => {
        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_additional_cost(
                this.state.accessToken,
                id_prescription,
                this.state.new_additional_cost_description,
                this.state.new_additional_cost_price
            );
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                });
                this.getPrescriptions();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }

    }

    handleDeleteAdditionalCost = async (id_additional_cost) => {
        this.setState({
            loading: true,
        })

        try {
            let response = await API.delete_additional_cost(
                this.state.accessToken,
                id_additional_cost
            );
            if (response.data.data != null) {
                this.setState({
                    loading: false,
                });
                this.getPrescriptions();
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }

    }

    handleAddNewCurrentMaterial = (e, data) => {
        var current_materials = [...this.state.current_materials];

        var index = this.state.materials.findIndex(element => element.key == data.value);
        var el = {
            id_material: data.value,
            batch_number: '',
            quantity: 1,
            name_it: this.state.materials[index].text
        }

        const result = current_materials.findIndex(element => element.id_material == el.id_material);
        if (result === -1) {
            current_materials.push(el)

        }

        this.setState({
            current_materials: current_materials,
        });
    }

    render() {

        let prescriptions = this.state.prescriptions.map(p => {

            let message;

            let addition_costs = p.additional_costs.map(a => {
                if (a.price != 0) {
                    return (
                        <Form.Group key={a.id_additional_cost}>
                            <Form.Input width={13} label={strings.description} value={a.description} />
                            <Form.Input width={2} label={strings.price} value={a.price} />
                            <Form.Button onClick={() => this.handleDeleteAdditionalCost(a.id_additional_cost)} width={1} label='.' color='red' icon><Icon name='trash'></Icon></Form.Button>
                        </Form.Group>
                    )
                }
            });

            let tasks = p.tasks.map(t => {
                const workslistOptions = this.state.workslist.map(w => {
                    var index = w.personalisations.map(function (o) {
                        return o.id_dentist;
                    }).indexOf(p.id_dentist);

                    if (index === -1) {
                        return { key: w.id_work, value: w.id_work, text: w.code + " - " + w.name_fr + " (" + w.price + "€)" }
                    } else {
                        return { key: w.id_work, value: w.id_work, text: w.personalisations[index].code + " - " + w.name_fr + " (" + w.personalisations[index].price + "€)" }
                    }
                })
                var total = 0;
                t.works.map(e => total += e.price);

                if (!t.inspector) {
                    return (
                        <Grid.Row verticalAlign='middle' key={t.id_task}>
                            <Grid.Column textAlign='center' width={2}>
                                <Image spaced circular
                                    style={{ width: "35px", height: "35px", objectFit: 'cover' }}
                                    src={t.url_image}>
                                </Image>
                                <br />
                                <Label basic>{t.name + " " + t.surname}</Label>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <b>{t.inspector ? strings.inspection_label : t.type.length > 60 ? t.type.substring(0, 60) + "..." : t.type}</b>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Input loading={this.state.loading} fluid value={t.invoice_description != null ? t.invoice_description : ''}
                                    onChange={(e, value) => this.handleChangeInvoiceDescription(t.id_task, e, value)} />
                            </Grid.Column>

                            <Grid.Column textAlign='center' width={4}>
                                <TaskWorksModal
                                    task={t}
                                    works={workslistOptions}
                                    open={this.state.modalOpen}
                                    onChangeWork={this.handleChangeCurrentWork}
                                    onAddNewWork={this.handleAddNewCurrentWork}
                                    onChangeWorkQuantity={this.handleChangeCurrentWorkQuantity}
                                    onChangeWorkPayment={this.handleChangeCurrentWorkPayment}
                                    onChangeWorkDiscount={this.handleChangeCurrentWorkDiscount}
                                    current_works={this.state.current_works}
                                    onDeleteWork={(id) => this.handleDeleteCurrentWork(id)}
                                    onChangeCurrentTask={this.handleChangeCurrentTask}
                                    number={t.works.length}
                                    onSave={(id) => this.handleSaveTaskWorks(id)}
                                />
                                <TaskMaterialModal
                                    task={t}
                                    id_task={t.id_task}
                                    materials={t.materials_certification}
                                    handleChange={this.handleChangeBatchNumber}
                                    handleChangQuantity={this.handleChangeMaterialQuantity}
                                    all_materials={this.state.materials}
                                    current_materials={this.state.current_materials}
                                    onDeleteMaterial={(id) => this.handleDeleteCurrentMaterial(id)}
                                    onSave={(id) => this.handleSaveTaskMaterials(id)}
                                    onChangeCurrentTask={this.handleChangeCurrentTask}
                                    onAddNewMaterial={this.handleAddNewCurrentMaterial}


                                />
                            </Grid.Column>

                            <Grid.Column textAlign='center' width={2}>
                                <b>{total}</b>
                            </Grid.Column>
                        </Grid.Row>
                    )
                }
            });

            if (tasks.length == 0) {
                message = <Segment basic textAlign='center'>{strings.no_task}</Segment>
            }

            return (
                <Segment raised key={p.id_prescription}>
                    <Header as='h3'>
                        {strings.title_prescription} {p.id_prescription + " ( " + dateTimeFormatter(p.datetime.substring(0, 10)) + ") - " + p.dentist_description.toUpperCase() + " - " + p.dentist.name + " " + p.dentist.surname}

                        {!p.payment ? <Button loading={this.state.loading} onClick={() => this.confirmPayment(p.id_prescription)} className='third-color-background white-color' floated='right'>{strings.confirm}</Button> : <Button color='green' icon='check' floated='right'></Button>}

                    </Header>
                    <br></br>
                    <Grid divided stackable columns={3}>
                        {tasks.length > 0 ?
                            <Grid.Row key={0}>
                                <Grid.Column textAlign='center' width={2}>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={3}>
                                    <b>{strings.table_prescription_description}</b>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={5}>
                                    <b>{strings.table_prescription_description_invoice}</b>
                                </Grid.Column>

                                <Grid.Column textAlign='center' width={4}>

                                </Grid.Column>
                                <Grid.Column textAlign='center' width={2}>
                                    <b>{strings.price}</b>
                                </Grid.Column>
                            </Grid.Row>
                            : null}
                        {tasks.length > 0 ?
                            <Divider className='divider-no-margin' fitted></Divider> : null}

                        {tasks}
                    </Grid>
                    <Segment >
                        <Grid columns={2}>
                            <Grid.Row verticalAlign='middle'>
                                <Grid.Column>
                                    <Header>{strings.additional_costs} </Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <AdditionalCostModal
                                        new_additional_cost_description={this.state.new_additional_cost_description}
                                        new_additional_cost_price={this.state.new_additional_cost_price}
                                        onChangeNewAdditionalCostDescription={this.handleChangeNewAdditionalCostDescription}
                                        onChangeNewAdditionalCostPrice={this.handleChangeNewAdditionalCostPrice}
                                        onSave={() => this.handleAddAdditionalCost(p.id_prescription)}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Form>
                            {addition_costs}
                        </Form>

                    </Segment>

                    <Segment textAlign='right'>
                        <Header>{strings.total}: {p.price}  € </Header>
                    </Segment>
                    {message}
                </Segment>
            )
        });

        let prev, next, page;

        if (this.state.pagination) {

            if (this.state.pagination.prev) {
                prev =
                    <Menu.Item onClick={() => this.changePage(this.state.pagination.prev)} as='a' icon>
                        <Icon name='chevron left' />
                    </Menu.Item>
            }
            if (this.state.pagination.next) {
                next =
                    <Menu.Item onClick={() => this.changePage(this.state.pagination.next)} as='a' icon>
                        <Icon name='chevron right' />
                    </Menu.Item>
            }
        }

        return (
            <div>

                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_ACCOUNTING} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>
                            <NavigationHeader title={strings.accounting}></NavigationHeader>
                            <Input
                                value={this.state.cod_prescription}
                                icon='search' placeholder={strings.table_id_prescription2}
                                onChange={this.handleChangePrescriptionCode}
                            />
                            <Loader active={this.state.loading} disabled={!this.state.loading} />
                            {prescriptions}
                            <div style={{ textAlign: 'right' }}>
                                <Menu pagination>
                                    {prev}{next}
                                </Menu>
                            </div>
                        </Segment>
                    </Grid.Column>
                </Grid>


            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withCookies,
    withRouter
)(Accounting)