import React from 'react'
import { Button, Icon, Modal, Header } from 'semantic-ui-react'
import { strings } from '../core/strings'

const ErrorModal = (props) => (
  <Modal open={props.open}>
    <Header icon='times circle outline' color='red' />
    <Modal.Content>
    <p style={{fontSize:"large"}}>
        {props.error_message != undefined ? props.error_message : strings.message_error_standard}
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button basic onClick={props.close}>
        <Icon name='remove' /> {strings.message_action_ok}
      </Button>
    </Modal.Actions>
  </Modal>
)

export default ErrorModal