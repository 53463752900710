import React, { Component } from 'react';
import { Grid, Segment, Loader } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import NavigationHeader from './NavigationHeader';
import PrescriptionsTable from './PrescriptionsTable';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';

class Prescriptions extends Component {

    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);

        this.state = {
            accessToken: accessToken,
            prescriptions: [],
            pagination: "",
            loading: false,
            openConfirmRequestAlert: false,
            openRejectRequestAlert: false,
            currentRequestId: null,
            userMessage: null,
            orderby: this.props.orderby,
            active: this.props.active,
            filters: this.props.filters,
            page: this.props.page
        };
    }

    changeSort = (value) => {

        let new_order = 'asc';
        let other_order = 'asc';

        var new_orderby = [...this.state.orderby]

        let index_asc = new_orderby.indexOf("order_by_" + value + "_asc");
        let index_desc = new_orderby.indexOf("order_by_" + value + "_desc");

        if (index_asc > -1) {
            new_order = "desc"
            other_order = "asc"
        }

        if (index_desc > -1) {
            new_order = "asc"
            other_order = "desc"
        }

        var index = new_orderby.indexOf("order_by_" + value + "_" + other_order);
        if (index > -1) {
            new_orderby.splice(index, 1);
        }
        
        new_orderby.unshift("order_by_" + value + "_" + new_order);

        this.setState({
            orderby: new_orderby
        });

        this.getPrescriptions(new_orderby, this.state.filters);
    }

    routeChange = (path) => {
        this.props.history.push(path);
    }

    componentDidMount() {
        if (this.state.page === null) {
            this.getPrescriptions(this.state.orderby, this.state.filters);
        } else {
            this.changePage(this.state.page);
        }
    }

    getPrescriptions = async (orderBy, searchFilters = []) => {

        this.setState({
            loading: true,
        })
        //alert(this.state.accessToken);

        try {
            let response = await API.get_prescriptions(this.state.accessToken, orderBy, searchFilters);
            if (response.data.data != null) {
                const prescriptions = [...response.data.data]
                const pagination = Object.assign({}, response.data.pagination)

                this.setState({
                    prescriptions: prescriptions,
                    pagination: pagination,
                    loading: false
                });

            } else if (response.data.error.code == '23000') {
                const { cookies } = this.props;
                cookies.remove('USER_TOKEN');
                this.props.history.push("/");
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }

    changePage = async (page) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_page(page);
            const prescriptions = [...response.data.data];
            const pagination = response.data.pagination;

            this.setState({
                prescriptions: prescriptions,
                pagination: pagination,
                loading: false,
                page: page
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    onEditPrescription = async (id) => {
        this.props.storeTableData(this.state.orderby, this.state.active, this.state.filters, this.state.page);
        this.routeChange(config.MENU_ITEM_PRESCRIPTIONS + "/" + id)
    }

    handleChangeCodPatientFilter = (e, { value }) => {
        var filters = Object.assign({}, this.state.filters);
        filters['patient_code'] = value
        this.setState({
            filters: filters
        })
        this.getPrescriptions(this.state.orderby, filters);

    }

    handleChangeDentistFilter = (e, { value }) => {
        var filters = Object.assign({}, this.state.filters);
        filters['dentist_description'] = value
        this.setState({
            filters: filters
        })
        this.getPrescriptions(this.state.orderby, filters);

    }

    handleChangeCodFilter = (e, { value }) => {
        var filters = Object.assign({}, this.state.filters);
        filters['id_prescription'] = value
        this.setState({
            filters: filters
        })
        this.getPrescriptions(this.state.orderby, filters);
    }

    render() {

        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_PRESCRIPTIONS} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>
                            <NavigationHeader title={strings.prescriptions}></NavigationHeader>
                            <Loader active={this.state.loading} disabled={!this.state.loading} />
                            <PrescriptionsTable
                                handleChangeCodFilter={this.handleChangeCodFilter}
                                handleChangeCodPatientFilter={this.handleChangeCodPatientFilter}
                                handleChangeDentistFilter={this.handleChangeDentistFilter}
                                prescriptions={this.state.prescriptions}
                                pagination={this.state.pagination}
                                onEditPrescription={(id) => this.onEditPrescription(id)}
                                changePage={this.changePage}
                                onChangeSort={(value) => this.changeSort(value)}
                                active={this.state.orderby}
                                cod_filter={this.state.filters.id_prescription}
                                cod_patient_filter={this.state.filters.patient_code}
                                dentist_description_filter={this.state.filters.dentist_description_filter}
                            />

                        </Segment>
                    </Grid.Column>
                </Grid>


            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        orderby: state.data_rdc.prescriptions_orderby,
        active: Object.assign({}, state.data_rdc.prescriptions_active),
        filters: Object.assign({}, state.data_rdc.prescriptions_filters),
        page: state.data_rdc.page
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeTableData: (orderby, active, filters, page) => dispatch({ type: actionTypes.SET_PRESCRIPTIONS_TABLE_DATA, orderby: orderby, active: active, filters: filters, page: page })
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withCookies,
    withRouter
)(Prescriptions)