import React from 'react'
import { Button, Modal, Form, Segment, Icon, Divider } from 'semantic-ui-react'
import { strings } from '../core/strings';

function AdditionalCostModal(props) {
    const [open, setOpen] = React.useState(false)

    const onClick = () => {
        props.onSave();
        setOpen(false);
    }

    return (
        <Modal size='small'
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button floated='right' className='third-color-background white-color' icon><Icon name='plus' /></Button>}
        >
            <Modal.Header>{props.title} </Modal.Header>

            <Modal.Content >
                <Form >
                    <h3>{strings.add_additinal_cost}</h3>
                    <Form.Group>
                        <Form.Input value={props.new_additional_cost_description}
                            width={14} label={strings.description} onChange={props.onChangeNewAdditionalCostDescription} />
                        <Form.Input type='number' value={props.new_additional_cost_price}
                            width={2} label={strings.price} onChange={props.onChangeNewAdditionalCostPrice} />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button className='secondary-color-background' onClick={() => setOpen(false)}>
                    {strings.message_action_giveup}
                </Button>
                <Button
                    content={strings.save_user_button}
                    labelPosition='right'
                    icon='save'
                    onClick={() => onClick()}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default AdditionalCostModal
