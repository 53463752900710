import React from 'react';
import './App.css';
import { CookiesProvider } from 'react-cookie';
import Home from './components/Home'

function App() {
  return (
    <div className="App">
      <CookiesProvider>
        <Home />
      </CookiesProvider>
    </div>
  );
}

export default App;
