import React from 'react'
import { Table, Icon, Button, Input, Segment, Menu, Image, Label } from 'semantic-ui-react'
import { strings } from '../core/strings';
import dateTimeFormatter from '../utils/dateTimeFormatter'
import moment from 'moment';

const status_label = [
    strings.status_preparing,
    strings.status_progress,
    strings.status_completed,
    strings.status_shipped
];

const status_label_color = [
    'grey',
    'yellow',
    'blue',
    'green'
];

const PrescriptionsTable = (props) => {

    let rows = props.prescriptions.map(p => {

        const images = p.tasks.map(t => {
            return (
                <Image className={t.completed ? 'image_operator_green' : 'image_operator_red'} bordered circular style={{ width: "40px", height: "40px", objectFit: 'cover' }} inline src={t.url_image} >
                </Image>
            )
        });

        var date1 = moment(new Date(), 'M/D/YYYY');
        var date2 = moment(p.date_requested, 'YYYY/MM/DD').format('M/D/YYYY');
        var diffDays = date1.diff(date2, 'days');

        var time = p.time_requested != null ? p.time_requested.substring(0, 5) : "";

        return (
            <Table.Row negative={(p.working_progress < 100 && Math.abs(diffDays) < 3) || (p.status != 3 && diffDays > 0) || (p.tasks.length > 5 && p.status != 3)} textAlign='center' key={p.id_prescription}>
                <Table.Cell width='1'> {p.id_prescription + " - #" + p.box_number} </Table.Cell>
                <Table.Cell>{p.patient_code}</Table.Cell>
                <Table.Cell>{p.dentist_description}</Table.Cell>
                <Table.Cell>{p.description.substring(0, 40) + "..."}</Table.Cell>
                <Table.Cell width='1'>{dateTimeFormatter(p.date_requested) + time}</Table.Cell>
                <Table.Cell width='2'><Label color={status_label_color[p.status]}>{status_label[p.status]}</Label></Table.Cell>
                <Table.Cell width='1'>{dateTimeFormatter(p.datetime)}</Table.Cell>
                <Table.Cell width='2'>

                    {images}

                </Table.Cell>
                <Table.Cell width='2'>
                    <Button compact className='third-color-background white-color'
                        onClick={() => props.onEditPrescription(p.id_prescription)} >
                        <Icon name='edit outline' />
                        {strings.action_prescription_button_label}
                    </Button>
                </Table.Cell>
            </Table.Row>
        )
    });

    let prev, next, page;

    if (props.pagination) {

        if (props.pagination.prev) {
            prev =
                <Menu.Item onClick={() => props.changePage(props.pagination.prev)} as='a' icon>
                    <Icon name='chevron left' />
                </Menu.Item>
        }
        if (props.pagination.next) {
            next =
                <Menu.Item onClick={() => props.changePage(props.pagination.next)} as='a' icon>
                    <Icon name='chevron right' />
                </Menu.Item>
        }
    }

    const date_requested_sort = props.active.indexOf("order_by_date_requested_asc") !== -1 ? 'ascending' :
        props.active.indexOf("order_by_date_requested_desc") !== -1 ? 'descending' : null;

    const status_sort = props.active.indexOf("order_by_status_asc") !== -1 ? 'ascending' :
        props.active.indexOf("order_by_status_desc") !== -1 ? 'descending' : null;

    const order_data = props.active[0] == 'order_by_date_requested_asc' || props.active[0] == 'order_by_date_requested_desc' ? "(1)" :
        props.active[1] == 'order_by_date_requested_asc' || props.active[1] == 'order_by_date_requested_desc' ? '(2)': '';

    const order_status = props.active[0] == 'order_by_status_asc' || props.active[0] == 'order_by_status_desc' ? "(1)" :
        props.active[1] == 'order_by_status_asc' || props.active[1] == 'order_by_status_desc' ? '(2)': '';

    return (
        <div>
            <Table selectable sortable celled structured>
                <Table.Header>
                    <Table.Row textAlign='center'>
                        <Table.HeaderCell rowSpan='1'>{strings.table_id_prescription}
                            <p></p>
                            <Input value={props.cod_filter} style={{ maxWidth: "40px" }} size='mini' onChange={props.handleChangeCodFilter}>
                            </Input>
                        </Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_cod_patient}
                            <p></p>
                            <Input style={{ maxWidth: "120px" }} value={props.cod_patient_filter} size='mini' onChange={props.handleChangeCodPatientFilter}></Input>
                        </Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_dentist_description}
                            <p></p>
                            <Input style={{ maxWidth: "120px" }} value={props.dentist_description_filter} size='mini' onChange={props.handleChangeDentistFilter}></Input>
                        </Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>
                            {strings.table_prescription_description}
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={date_requested_sort} 
                            onClick={() => props.onChangeSort('date_requested')}
                            rowSpan='1'>{strings.table_prescription_date_requested} <br />{strings.table_prescription_date_requested2} {order_data}
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={status_sort}
                            onClick={() => props.onChangeSort('status')}
                            rowSpan='1'>{strings.table_prescription_status} {order_status}
                        </Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.table_prescription_datetime}<br />{strings.table_prescription_datetime2}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'>{strings.plan_title}</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='1'></Table.HeaderCell>
                    </Table.Row>

                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell textAlign='center' colSpan="11">
                            <Menu floated='right' pagination>
                                {prev}{next}
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>

    );
}


export default (PrescriptionsTable);

