import React, { Component } from 'react';
import "../App.css";

class CanvasModal extends Component {
    constructor(props) {
        super(props);


        this.state = {
        }

    }

    componentDidMount() {
        if(document.getElementById("canvas_container_modal") != null){
            document.getElementById("canvas_container_modal").innerHTML = "";
        }

        if(document.getElementById("canvas_container_modal") != null ) {
            window.init("canvas_container_modal");
        }else{
        }
    }

    render() {

        return (
            <div>
                <div className='MyContainer'>

                    <div id="canvas_container_modal">

                    </div>
                </div>

            </div>
        );
    }
}

export default(CanvasModal)